import { ResourceRecord } from './dns-zone-editor.types';

export class Formatter {
	public formatRecordsForApi(records: ResourceRecord[], domainName: string) {
		// format records for API
		records.forEach((record) => {
			record.name = record.name.trim().toLowerCase();
			if (record.name === '@') {
				record.name = domainName + '.';
			}

			if (record.name.endsWith(domainName)) {
				record.name += '.';
			}

			if (!record.name.endsWith(domainName + '.')) {
				record.name += record.name.endsWith('.')
					? domainName + '.'
					: '.' + domainName + '.';
			}

			if (record.type !== 'CAA') {
				delete record.rdata.caaFlag;
				delete record.rdata.caaTag;
			}

			if (record.type === 'CNAME') {
				record.rdata.cnameValue = record.rdata.cnameValue
					.trim()
					.toLowerCase();
				if (record.rdata.cnameValue === '@') {
					record.rdata.cnameValue = domainName;
				}
				record.rdata.cnameValue = record.rdata.cnameValue.replace(
					/([^\.]$)/,
					'$1.'
				);
			}

			if (record.type === 'MX') {
				record.rdata.mxDestination = record.rdata.mxDestination
					.trim()
					.toLowerCase();
				record.rdata.mxDestination = record.rdata.mxDestination.replace(
					/([^\.]$)/,
					'$1.'
                );
                // Normalize MX records to include the domain name
                // Fix for @
                if (record.rdata.mxDestination === 'mail.') {
                    record.rdata.mxDestination = 'mail.' + domainName + '.';
                }
			}

			if (record.type === 'NS') {
				record.rdata.nsValue = record.rdata.nsValue
					.trim()
					.toLowerCase();
				record.rdata.nsValue = record.rdata.nsValue.replace(
					/([^\.]$)/,
					'$1.'
				);
			}

			if (record.type === 'SOA') {
				record.rdata.soaMname = record.rdata.soaMname
					.trim()
					.toLowerCase();
				record.rdata.soaMname = record.rdata.soaMname.replace(
					/([^\.]$)/,
					'$1.'
				);

				record.rdata.soaRname = record.rdata.soaRname
					.trim()
					.toLowerCase();
				record.rdata.soaRname = record.rdata.soaRname.replace(
					/\.+(?=[^@]*@)/g,
					'\\.'
				);
				record.rdata.soaRname = record.rdata.soaRname.replace('@', '.');
				record.rdata.soaRname = record.rdata.soaRname.replace(
					/([^\.]$)/,
					'$1.'
				);

				// auto-increment SOA Serial
				const serial =
					new Date().toISOString().substr(0, 10).replace(/-/g, '') +
					'01';
				if (parseInt(serial, 10) > record.rdata.soaSerial) {
					record.rdata.soaSerial = parseInt(serial, 10);
				} else {
					record.rdata.soaSerial =
						++record.rdata.soaSerial > 4294967295
							? 0
							: record.rdata.soaSerial;
				}
			}

			if (record.type === 'SRV') {
				record.rdata.srvTarget = record.rdata.srvTarget
					.trim()
					.toLowerCase();
				record.rdata.srvTarget = record.rdata.srvTarget.replace(
					/([^\.]$)/,
					'$1.'
				);
			}

			if (record.type === 'TXT') {
				const segments = record.rdata.txtValue.match(/(.{1,255})/g);
				let txtValue = '';
				segments.forEach(function (segment) {
					txtValue += '"' + segment + '" ';
				});
				record.rdata.txtValue = txtValue.trim();
			}
		});
	}
}
