import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ApiService, ProfileService } from '@central/ng-shared';
import { Config } from '../../../shared/config/env.config';

import { ResourceRecord } from './dns-zone-editor.types';
import { Formatter } from './dns-zone-editor.formatter';
import { AppService } from '../../../app.service';
import { DnsRecordDeleteDialogComponent } from './dns-record-delete/dns-record-delete-dialog.component';

const MAX_UNSIGNED_16_BIT_INTEGER = 65535;
const MAX_SIGNED_32_BIT_INTEGER = 2147483647;

@Component({
	selector: 'central-dns-zone-editor',
	templateUrl: './dns-zone-editor.component.html',
	styleUrls: ['./dns-zone-editor.component.scss'],
})
export class DnsZoneEditorComponent implements OnInit, OnDestroy {
	public domainName: string;
    public domainId: string;
    public isControlled = false;

	public getRequestState = 'loading';

	public records: ResourceRecord[];

	public displayedColumns: string[] = [
		'name',
		'type',
		'time-to-live',
		'value',
		'actions',
	];
	public recordTypes: string[] = [
		'A',
		'AAAA',
		'CAA',
		'CNAME',
		'MX',
		'NS',
		/* 'SOA',*/ 'SRV',
		'TXT',
	];

	public addingRecord = false;

	public editedRecord: ResourceRecord;
	public formatter = new Formatter();
	public editingRecordIndex = -1;

	public deletingRecordIndex = -1;
	public deleteRequestState = 'inactive';

	public saveRequestState = 'inactive';

	public ttlFormControl = new UntypedFormControl('', [
		Validators.min(0),
		Validators.max(MAX_SIGNED_32_BIT_INTEGER),
	]);
	public ipv4FormControl = new UntypedFormControl(
		'',
		Validators.pattern(
			'^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(.(?!$)|$)){4}$'
		)
	);
	// public ipv6FormControl = new FormControl('', Validators.pattern('^(([0-9a-f]){4}(:(?!$)|$)){8}$'));
	public mxPriorityFormControl = new UntypedFormControl('', [
		Validators.min(0),
		Validators.max(MAX_UNSIGNED_16_BIT_INTEGER),
	]);
	public soaRefreshFormControl = new UntypedFormControl('', [
		Validators.min(0),
		Validators.max(MAX_SIGNED_32_BIT_INTEGER),
	]);
	public soaRetryFormControl = new UntypedFormControl('', [
		Validators.min(0),
		Validators.max(MAX_SIGNED_32_BIT_INTEGER),
	]);
	public soaExpireFormControl = new UntypedFormControl('', [
		Validators.min(0),
		Validators.max(MAX_SIGNED_32_BIT_INTEGER),
	]);
	public soaMinimumFormControl = new UntypedFormControl('', [
		Validators.min(0),
		Validators.max(MAX_SIGNED_32_BIT_INTEGER),
	]);
	public srvPriorityFormControl = new UntypedFormControl('', [
		Validators.min(0),
		Validators.max(MAX_UNSIGNED_16_BIT_INTEGER),
	]);
	public srvWeightFormControl = new UntypedFormControl('', [
		Validators.min(0),
		Validators.max(MAX_UNSIGNED_16_BIT_INTEGER),
	]);
	public srvPortFormControl = new UntypedFormControl('', [
		Validators.min(0),
		Validators.max(MAX_UNSIGNED_16_BIT_INTEGER),
	]);

	private subscriptions = [];

	constructor(
		private appService: AppService,
		private apiService: ApiService,
		private profileService: ProfileService,
		private route: ActivatedRoute,
		private httpClient: HttpClient,
		private dialog: MatDialog,
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				const domainName = this.route.snapshot.paramMap.get('domainName');
				this.appService.getThirdPartyDomains().subscribe((thirdPartyDomains) => {
					const thirdPartyDomain = thirdPartyDomains
						.find(domain => domain.label === domainName)

					if (!thirdPartyDomains ||
						!thirdPartyDomains.length ||
						!thirdPartyDomain) {
						this.isControlled = true;
						this.domainName = domainName;
						this.getRecords();
					} else if (thirdPartyDomain) {
						this.domainId = thirdPartyDomain?.id;
						this.domainName = thirdPartyDomain?.label;
						this.getRecords();
					} else {
						this.getRequestState = 'failed';
					}
				})
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'add':
				this.addRecord();
				break;
		}
	}

	private getRecords() {
		const url =
			Config['host'] +
			'/v1/domain-service/dns-zone/' +
			(this.domainId || this.domainName);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		this.httpClient.get(url, { headers }).subscribe(
			(response) => {
				this.records = response as ResourceRecord[];

				this.records.sort(function (firstRecord, secondRecord) {
					if (firstRecord.type > secondRecord.type) {
						return 1;
					}

					if (firstRecord.type < secondRecord.type) {
						return -1;
					}

					return 0;
				});

				// format records for display
				this.records.forEach(function (record) {
					record.name = record.name.replace(/^@$/, this.domainName);

					if (!record.name.endsWith(this.domainName)) {
						record.name += '.' + this.domainName;
					}

					if (record.type === 'CAA') {
						record.rdata.caaValue = record.rdata.caaValue.replace(
							/^\"(.*)\"$/,
							'$1'
						);
					}

					if (record.type === 'CNAME') {
						record.rdata.cnameValue =
							this.formatDomainNameForDisplay(
								record.rdata.cnameValue
							);
					}

					if (record.type === 'MX') {
						record.rdata.mxDestination =
							this.formatDomainNameForDisplay(
								record.rdata.mxDestination
							);
					}

					if (record.type === 'NS') {
						record.rdata.nsValue = this.formatDomainNameForDisplay(
							record.rdata.nsValue
						);
					}

					if (record.type === 'SOA') {
						record.rdata.soaMname = record.rdata.soaMname.replace(
							/\.$/,
							''
						);

						if (record.rdata.soaRname.includes('\\@')) {
							record.rdata.soaRname =
								record.rdata.soaRname.replace(/\\@/, '@');
						} else {
							function replaceDot(match) {
								return match[0] + '@';
							}
							record.rdata.soaRname =
								record.rdata.soaRname.replace(
									/(?:[^\\])\./,
									replaceDot
								);
							record.rdata.soaRname =
								record.rdata.soaRname.replace(/\\\./g, '.');
						}
						record.rdata.soaRname = record.rdata.soaRname.replace(
							/\.$/,
							''
						);
					}

					if (record.type === 'SRV') {
						record.rdata.srvTarget =
							this.formatDomainNameForDisplay(
								record.rdata.srvTarget
							);
					}

					if (record.type === 'TXT') {
						record.rdata.txtValue = record.rdata.txtValue.replace(
							/^\"(.*)\"$/,
							'$1'
						);
						record.rdata.txtValue = record.rdata.txtValue.replace(
							/\" \"/g,
							''
						);
					}
				}, this);

				this.getRequestState = 'succeeded';
			},
			(error) => {
				if (error.status === 404) {
					this.records = [];
					this.getRequestState = 'notfound';
				} else {
					this.getRequestState = 'failed';
				}
			}
		);
	}

	private formatDomainNameForDisplay(domainName: string) {
		// replace @ with domain name
		domainName = domainName.replace(/^@$/, this.domainName + '.');

		// append domain name to subdomains
		//domainName = domainName.replace(/([^\.]$)/, '$1.' + this.domainName);

		// strip trailing dot
		domainName = domainName.replace(/\.$/, '');

		return domainName;
	}

	public addRecord() {
		const newRecord: ResourceRecord = {
			name: '',
			type: 'A',
			ttl: 14400,
			rdata: {
				// CAA record defaults; will be deleted on save if not applicable
				caaFlag: 0,
				caaTag: 'issue',
			},
		};

		this.records = [newRecord, ...this.records];
		this.editedRecord = newRecord;

		this.addingRecord = true;
		this.editingRecordIndex = 0;
	}

	public editRecord(index: number) {
		this.editedRecord = JSON.parse(JSON.stringify(this.records[index]));
		this.editingRecordIndex = index;
	}

	public deleteRecord(index: number) {
		const dialogRef = this.dialog.open(DnsRecordDeleteDialogComponent, {
			maxWidth: '1000px'
		});

		dialogRef.componentInstance.record = this.records[index];
		dialogRef.componentInstance.domain = this.domainName;

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.deletingRecordIndex = index;
				this.deleteRequestState = 'loading';

				const recordsCopy = JSON.parse(JSON.stringify(this.records));
				recordsCopy.splice(index, 1);
				this.formatter.formatRecordsForApi(
					recordsCopy,
					this.domainName
				);

				const url = Config['host'] + '/v1/domain-service/dns-zone/';
				const headers = this.apiService.getHeaders({
					contentType: 'application/json',
				});
				const body = {
					domainName: this.domainId ?? this.domainName,
					zoneData: recordsCopy,
				};
				this.httpClient.patch(url, body, { headers }).subscribe(
					(response) => {
						this.deleteRequestState = 'inactive';
						this.getRecords();
					},
					(error) => {
						this.deleteRequestState = 'inactive';
						this.appService.showError('Error deleting record');
					}
				);
			}
		});
	}

	public saveRecord(index: number) {
		this.saveRequestState = 'loading';

		const recordsCopy = JSON.parse(JSON.stringify(this.records));
		recordsCopy[index] = JSON.parse(JSON.stringify(this.editedRecord));
		this.formatter.formatRecordsForApi(recordsCopy, this.domainName);

		const url = Config['host'] + '/v1/domain-service/dns-zone/';
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const body = {
			domainName: this.domainId ?? this.domainName,
			zoneData: recordsCopy,
		};
		this.httpClient.patch(url, body, { headers }).subscribe(
			(response) => {
				this.saveRequestState = 'inactive';
				this.editingRecordIndex = -1;

				if (this.addingRecord) {
					this.addingRecord = false;
				}
				this.getRecords();
			},
			(error) => {
				this.saveRequestState = 'inactive';
				this.appService.showError('Error saving record. Is it formatted correctly?');
			}
		);
	}

	public cancelEditRecord() {
		this.editingRecordIndex = -1;

		if (this.addingRecord) {
			this.addingRecord = false;
			this.records.shift();
			this.records = JSON.parse(JSON.stringify(this.records));
		}
	}
}
