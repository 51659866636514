import { ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { ApiService, CENTRAL_ENVIORNMENT_CONFIG, ConfigurationService } from ".";
import { BrandingService } from "./branding/branding.service";
import { InvalidInputService } from "./forms/invalid-input.service";
import { DecoderService } from "./jwt/decoder";
import { LocalStorageService } from "./local-storage/local-storage.service";
import { PublicStorageService } from "./local-storage/public-storage.service";
import { SharedStorageService } from "./local-storage/shared-storage.service";
import { ScriptService } from "./script/script.service";
import { AuthService, ProfileService } from "../authentication/services";
import { CoreComponent } from "./core.component";

/**
 * The CoreServicesModule is a module that provides all the core services that are used throughout the application.
 * 
 * @description
 * The `CoreServicesModule` is a module that provides all the core services that are used throughout the application.
 * 
 */
@NgModule({
    declarations: [
        CoreComponent,
    ],
    providers: [
        InvalidInputService,
        ScriptService,
        DecoderService,
        BrandingService,
        LocalStorageService,
        SharedStorageService,
        PublicStorageService,
        ApiService,
        ConfigurationService,
    ],
    exports: [
        CoreComponent,
    ]})
export class CoreServicesModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreServicesModule) {
        if (parentModule) {
            throw new Error(
              'CoreServicesModule is already loaded at root. Import it in the AppModule only!');
          }
    }
    static forRoot(environment): ModuleWithProviders<CoreServicesModule> {
		return {
			ngModule: CoreServicesModule,
            providers: [
                {
                    provide: CENTRAL_ENVIORNMENT_CONFIG,
                    useValue: environment,
                },
            ]
		};
	}
}