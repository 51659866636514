import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService, ConfigurationService } from '@central/ng-shared';
import { driver } from "driver.js";
import { HttpClient, HttpResponse } from '@angular/common/http';

interface Tour {
  key: string;
  validUrls: string[];
  alwaysShow: boolean;
  options: {
    steps: any[];
    // ... other driver.js options
  };
}

@Injectable()
export class TourService {

	public tours;
	public currentIndex = 0;
	private driverObj;

	constructor(
		private router: Router,
		public httpClient: HttpClient,
		public configService: ConfigurationService,
		public localStorage: LocalStorageService,
	) {}

	/**
	 * Fetches tour configurations from remote JSON if not already loaded
	 * Once tours are loaded, initiates the tour sequence
	 */
	public fetchTours() {
		if (typeof this.tours === 'undefined') {
			this.httpClient.get<Tour[]>(this.getTourUrl()).subscribe(
				(response: any[]) => {
					this.tours = response;
					this.runTours();
				}
			);
		} else {
			this.runTours();
		}
	}

	/**
	 * Executes the current tour if conditions are met:
	 * - Current URL matches tour's valid URLs
	 * - Tour is set to always show OR hasn't been shown before
	 * If conditions aren't met, moves to next tour
	 */
	public runTours() {
		if (this.tours.length <= 0) {
			return;
		}
		const tour = this.tours[this.currentIndex];
		if (
			tour?.validUrls.includes(this.router.url) &&
			(tour.alwaysShow || typeof this.localStorage.getItem('tours-' + tour.key) === 'undefined')
		   ) {
			this.localStorage.setItem('tours-' + tour.key, new Date().toISOString());
			this.driverObj = driver({
				...tour.options,
				...{ onDestroyStarted: () => this.incrementTour() }
			});
			this.driverObj.drive();
		} else {
			this.incrementTour();
		}
	}

	/**
	 * Advances to the next tour in the sequence
	 * Destroys current driver.js instance if it exists
	 * Recursively runs next tour if available
	 */
	public incrementTour() {
		this.currentIndex++;
		if (this.driverObj) {
			this.driverObj.destroy();
		}
		if (this.currentIndex <= this.tours.length - 1) {
			this.runTours();
		}
	}

	/**
	 * Constructs the URL for fetching tour configurations
	 * Appends '-dev' suffix for non-production environments
	 * @returns {string} The complete URL for tour JSON file
	 */
	private getTourUrl() {
		let url = `https://inmotionhosting.github.io/static-assets/tours/${this.configService.config.appPrefix}`;
		if (this.configService.config.environment !== 'production') {
			url = url + '-dev';
		}
		url = url + '.json';
		return url;
	}
}
