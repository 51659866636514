import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import LogRocket from 'logrocket';
import { AuthService } from '../../authentication/services/auth.service';
import { ConfigurationService } from '../../core/configuration.service';
import { DecoderService } from '../../core/jwt/decoder';
import { RollbarService } from '../rollbar/rollbar.service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class LogrocketService {
	public privateEndpoints = [
		// Passwords.
		'/account/v1/auth/reset-password',
		'/account/v1/auth/update-password',
		'/account/v1/auth/change-password',

		// Billing
		'/payments/method',

		// Login
		'/account/v1/auth/get-token',
		'/v1/accounts/provider-auth',
	];


	constructor(
		public configurationService: ConfigurationService,
		public decoderService: DecoderService,
		public rollbarService: RollbarService,
		public authService: AuthService,
        private location: Location,
	) {
		const config = this.configurationService.config;

		if (!config.logrocket?.appId && !config.logrocket?.routeOverrides) {
			console.log('Logrocket not configured');
			return;
		}

        let appId = '';
        if (config.logrocket?.routeOverrides) {
            const override = config.logrocket?.routeOverrides.find(
                item => this.location.path().startsWith(item.route)
            )
        
            appId = override?.appId ?? config.logrocket.appId;
        } else {
            appId = config.logrocket.appId;
        }

		LogRocket.init(appId, {
			rootHostname: config.logrocket?.rootHostname,
			network: {
				requestSanitizer: (request) => {
					const privateEndpoints = [
						...(config.logrocketPrivateEndpoints || []),
						...this.privateEndpoints,
					];

					// Dont pass request to LogRocket.
					for( const ignoreEndpoint of privateEndpoints ) {
						if( typeof(request.url) === 'string') {
							if ( request.url.includes( ignoreEndpoint ) ) {
								return null;
							}
						}
					}

					// Sanitize sensitive data for specific endpoints
					request = this.sanitizeSpecificInfo(request);

					request.headers['authorization'] = null;
					return request;
				},
			},
		});

		this.configureLogrocket();
		this.configureRollbar();

		this.authService.authStateChange.subscribe(() => {
			if (this.authService.isLoggedIn()) {
				this.configureLogrocket();
				this.configureRollbar();
			}
		});
	}

	configureLogrocket() {
		const accountId = this.decoderService.getTokenVal('user_id') || '';
		const email = this.decoderService.getTokenVal('email') || '';

		LogRocket.identify(accountId, {
			email: email,
		});
	}

    configureAmpOpLogrocket(data: any) {
		const name = data.name;
		const email = data.email;
        const company = data.company;

		LogRocket.identify(data.email, {
            name: name,
			email: email,
            company: company
		});
	}

	configureRollbar() {
		this.rollbarService.rollbar.configure({
			transform: (obj) => {
				obj.environmentUrl = location.protocol + '//' + location.host;
				obj.sessionURL = LogRocket.sessionURL;
				obj.person = {
					id: this.decoderService.getTokenVal('user_id') || '',
					email: this.decoderService.getTokenVal('email') || '',
				};
				return obj;
			},
		});
	}

	/**
	 * Sanitizes sensitive information from specific API requests before sending to LogRocket.
	 * This method handles redaction of sensitive data like passwords, payment information,
	 * and credit card details for specific endpoints.
	 * 
	 * Supported endpoints and their sanitization:
	 * - /api/order/purchase: Redacts user password and payment details (card number, expiry, CSV)
	 * - /api/new-card/undefined: Redacts all credit card data
	 * - /api/user/authenticate: Redacts password
	 * - /api/user/check-password-strength: Redacts password
	 * 
	 * @param request - The request object to sanitize
	 * @param request.url - The endpoint URL of the request
	 * @param request.body - JSON string containing the request body
	 * @returns The sanitized request object with sensitive data redacted
	 */
	sanitizeSpecificInfo(request: any) {
		const paths = {
			'/api/order/purchase': function (body) {
				if (body.hasOwnProperty('User') && body.User.hasOwnProperty('Password')) {
					body.User.Password = 'REDACTED';
				}
				if (body.hasOwnProperty('Payment')) {
					if (body.Payment.hasOwnProperty('Number')) {
						body.Payment.Number = 'REDACTED';
					}
					if (body.Payment.hasOwnProperty('Expiry')) {
						body.Payment.Expiry = 'REDACTED';
					}
					if (body.Payment.hasOwnProperty('Csv')) {
						body.Payment.Csv = 'REDACTED';
					}
				}
				return body;
			},
			'/api/new-card/undefined': function (body) {
				if (body.hasOwnProperty('ccData')) {
					for (const i in body.ccData) {
						if( body.ccData.hasOwnProperty(i) ) {
							body.ccData[i] = 'REDACTED';
						}
					}
				}
				return body;
			},
			'/api/user/authenticate': function (body) {
				if (body.hasOwnProperty('password')) {
					body.password = 'REDACTED';
				}
				return body;
			},
			'/api/user/check-password-strength': function (body) {
				if (body.hasOwnProperty('password')) {
					body.password = 'REDACTED';
				}
				return body;
			}
		};

		const specialEndpoints = Object.keys(paths);

		for( const specialEndpoint of specialEndpoints ) {
			if( typeof(request.url) === 'string' && request.body ) {
				if ( request.url.includes( specialEndpoint )) {
					request.body = JSON.stringify(paths[specialEndpoint](JSON.parse(request.body)));
				}
			}
		}
		return request;
	}
}
