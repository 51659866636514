import { BreakpointObserver } from '@angular/cdk/layout';
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService, BrandingService, ProfileService } from '@central/ng-shared';
import { AccountService } from 'libs/ng-shared/src/lib/account/account.service';
import { OptimizelyService } from 'libs/ng-shared/src/lib/tracking/optimizely/optimizely.service';
import { Router, NavigationEnd } from '@angular/router';
import { AppService } from '../app.service';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { ProjectService } from '../project/project.service';

@Component({
	selector: 'central-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit {

	@Input() public authenticated = false;
	@Input() public hideMobileHeader = false;
	@Input() public defaultLanguage: string;

	@Output() public toggleMenu = new EventEmitter();

	@ViewChild(MatSidenav) public sidenav;

	public hasChildMenu = false;
	public openedDropdown = false;
	public isMobile = false;
	public activeMenuItem: string;
	public activeTeamId: string;
	public activeTeamName: string;
    public offer: any;
    public domainsUnderMgmt = 0;

	public showReadme = false;

	constructor(
		private appService: AppService,
		private mediaQuery: BreakpointObserver,
		private projectService: ProjectService,
		public brandingService: BrandingService,
		public authService: AuthService,
		public profileService: ProfileService,
        public accountService: AccountService,
		public optimizelyService: OptimizelyService,
		public router: Router
	) {}

	ngOnInit(): void {
		this.mediaQuery.observe('(max-width: 800px)').subscribe((res) => {
			this.isMobile = res.matches;
			this.applyResponsiveness();
		});
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (event.urlAfterRedirects.match(/^\/team/)) {
					this.activeMenuItem = 'team';
				} else if (event.urlAfterRedirects.match(/^\/account/)) {
					this.activeMenuItem = 'user';
				} else {
					this.activeMenuItem = '';
				}

				this.updateOffers(event);
			}
		});
		this.appService.getCatalog(false).subscribe();
		this.profileService.onProfileUpdate.subscribe(() => {
			this.activeTeamId = this.profileService.data.account_access.filter((team) => team.account_id === this.profileService.data.options.public_selected_team)[0]?.account_id;
			this.activeTeamName = this.profileService.data.account_access.filter((team) => team.account_id === this.profileService.data.options.public_selected_team)[0]?.display_name;
			if (!this.activeTeamId ||
				this.activeTeamId === this.profileService.data.account_access[0].account_id) {
				this.activeTeamId = this.profileService.data.account_access[1].account_id
				this.activeTeamName = this.profileService.data.account_access[1].display_name;
			}

			this.appService.getContextualHelp(false).subscribe()
			this.appService.getOffers().subscribe(() => {
				this.updateOffers({});
            });
            this.getDomainsUnderMgmt()

			if (this.authService.isImpersonating()) {
				const token = this.profileService.authService.jwtHelper.getTokenDecoded()['payload'];
				this.showReadme = token['readme'];
			} else {
				this.showReadme = this.profileService.data['readme'];
			}
		});
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.applyResponsiveness();
			if (window['Weglot']) {
				window['Weglot'].switchTo(this.defaultLanguage);
			}
		});
	}

	public applyResponsiveness() {
		if (this.sidenav) {
			if (this.isMobile) {
				this.sidenav.close();
			} else if (this.authenticated) {
				this.sidenav.open();
			}
		}
	}

	public getMode() {
		let mode = 'side';
		if (this.isMobile && !this.hasChildMenu) {
			mode = 'push';
		}

		return mode;
	}

	public openMenus() {
		this.sidenav.toggle();
		this.toggleMenu.emit();
	}

	public updateOffers(routerEvent: any) {
		this.appService.getOffers().subscribe((offer) => {
			// Default to server deals
			this.offer = offer['server'];
			// Display any other available offers based on route
			if (routerEvent.urlAfterRedirects?.includes('/domains')) {
				this.offer = offer['domain'] || offer['server'] || offer['email'];
			}
			if (routerEvent.urlAfterRedirects?.includes('/email')) {
				this.offer = offer['email'] || offer['server'] || offer['domain'];
			}
			if (this.offer?.coupon_code) {
				this.offer.type = this.offer.coupon_code.split('-')[1];
			}
		});
	}

	public openOffer() {
		const ref = this.appService.openDialog(DialogComponent, {
			panelClass: 'offer',
			titleIcon: this.offer.invoice_description.split('|')[0],
			titleIconClass: 'ctw-text-white',
			title: this.offer.invoice_description.split('|')[1],
			content: this.offer.invoice_description.split('|')[2],
			contentClass: 'ctw-text-white ctw-text-xl',
			html: true,
			confirmButton: this.offer.invoice_description.split('|')[3],
			confirmButtonClass: 'mat-stroked-button ctw-border-current ctw-text-white',
			cancelButton: 'Maybe Later',
			cancelButtonClass: 'ctw-text-white',
			//shouldCopy: true,
			//itemToCopy: this.tempAuth,
			//itemToCopyName: 'Temporary Password',
		});

		ref.afterClosed().subscribe((claimed) => {
			if (claimed) {
				switch (this.offer.type) {
					case 'server':
						this.projectService.organizationId =
							this.profileService.data.options.public_selected_team ||
								this.profileService.data.account_access[1];
						this.projectService.createProject({
							label: `${this.offer.invoice_description.split('|')[1]}`,
							state: 'live',
						})
						.subscribe(
							(response: any) => {
								this.router.navigate(['/projects', response.id]).then(() => {
									this.router.navigate(['/projects', response.id, 'add', 'server']).then(() => {
										this.appService.getProjects(false);
									})
								});
							},
							() => {}
						);
						break;
					case 'domain':
						break;
					case 'email':
						break;
				}
			}
		})
    }
    
    public getDomainsUnderMgmt() {
        this.domainsUnderMgmt = 0;
        this.appService.getFirstPartyDomains().subscribe((firstParty) => {
            this.domainsUnderMgmt += firstParty.length;
            this.appService.getThirdPartyDomains().subscribe((thirdParty) => {
                this.domainsUnderMgmt += thirdParty.length;
            });
        });
    }
}
