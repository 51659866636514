import { Component, OnInit, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../shared/config/env.config';
import { ApiService, ProfileService } from '@central/ng-shared';

@Component({
	selector: 'central-email-subscription-manage',
	templateUrl: './email-subscription-manage.component.html',
	styleUrls: ['./email-subscription-manage.component.scss'],
})
export class EmailSubscriptionManageComponent implements OnInit {

	@Input() public price: string;

	@Input() private accountId: string;
	@Input() private cpanelUsername: string;
	@Input() private thirdPartyDomainId: string;
	@Input() private domainName: string;
	@Input() private subscriptionUuid: string;

	public componentState = 'loading';

	public organizationRole: string;

	private singleSignOnLinkRequestState = 'loading';
	private emailAccountUsageRequestState = 'loading';
	private emailAccountLimitRequestState = 'loading';

	private singleSignOnLink: string;
	public emailAccountUsage: number;
	public emailAccountLimit: number;

	public showQuantityManager = false;
	public stepperQuantity: number;

	public updateQuantityRequestState = 'inactive';

	public dnsStatusState = 'inactive';
	private dnsRecordsRequestState = 'inactive';
	private cpanelIpAddressRequestState = 'inactive';
	private dkimValueRequestState = 'inactive';
	private dnsZoneRequestState = 'inactive';

	public dnsRecords = [];
	private cpanelIpAddress;
	public dnsIssuesCount = 0;
	public showDnsRecords = false;
	private dnsZoneData;
	public fixIssuesRequestState = 'inactive';

	constructor(
		private apiService: ApiService,
		private profileService: ProfileService,
		private clipboard: Clipboard,
		private httpClient: HttpClient
	) {}

	ngOnInit(): void {
		const access = this.profileService.data.account_access.find(
			(acc) => acc.account_id === this.accountId
		);
		if (access) {
			this.organizationRole = access.role;
		}

		this.getCpanelDetails();
	}

	public getCpanelDetails() {
		this.componentState = 'loading';
		this.getSingleSignOnLink();
		this.getEmailAccountUsage();
		this.getEmailAccountLimit();
	}

	private getSingleSignOnLink() {
		if ('member' !== this.organizationRole) {
			if ('success' !== this.singleSignOnLinkRequestState) {
				this.apiService
					.get(
						'/emails/v1/cpanel/usersession?username=' +
							this.cpanelUsername,
						{}
					)
					.subscribe(
						(response) => {
							if (response['data']?.url) {
								this.singleSignOnLink = response['data'].url;
								this.singleSignOnLinkRequestState = 'success';
							} else {
								this.singleSignOnLinkRequestState = 'failed';
							}
							this.updateComponentState();
						},
						() => {
							this.singleSignOnLinkRequestState = 'failed';
							this.updateComponentState();
						}
					);
			}
		} else {
			this.singleSignOnLinkRequestState = 'success';
		}
	}

	private getEmailAccountUsage() {
		if ('success' !== this.emailAccountUsageRequestState) {
			this.apiService
				.get('/emails/v1/mail/?username=' + this.cpanelUsername, {})
				.subscribe(
					(response) => {
						if (response['data']?.pops) {
							this.emailAccountUsage = response['data'].pops.length;
							this.emailAccountUsageRequestState = 'success';
						} else {
							this.emailAccountUsageRequestState = 'failed';
						}
						this.updateComponentState();
					},
					() => {
						this.emailAccountUsageRequestState = 'failed';
						this.updateComponentState();
					}
				);
		}
	}

	private getEmailAccountLimit() {
		if ('success' !== this.emailAccountLimitRequestState) {
			this.apiService
				.get(
					'/emails/v1/cpanel/mailmax?username=' + this.cpanelUsername,
					{}
				)
				.subscribe(
					(response) => {
						if (response['Max Email Accounts']) {
							this.emailAccountLimit = parseInt(response['Max Email Accounts'], 10);
							this.stepperQuantity = this.emailAccountLimit;
							this.emailAccountLimitRequestState = 'success';
						} else {
							this.emailAccountLimitRequestState = 'failed';
						}
						this.updateComponentState();
					},
					() => {
						this.emailAccountLimitRequestState = 'failed';
						this.updateComponentState();
					}
				);
		}
	}

	private updateComponentState() {
		if (
			this.singleSignOnLinkRequestState === 'success' &&
			this.emailAccountUsageRequestState === 'success' &&
			this.emailAccountLimitRequestState === 'success'
		) {
			this.componentState = 'success';
		} else if (
			this.singleSignOnLinkRequestState === 'failed' ||
			this.emailAccountUsageRequestState === 'failed' ||
			this.emailAccountLimitRequestState === 'failed'
		) {
			this.componentState = 'error';
		}
	}

	public openCpanel() {
		window.open(this.singleSignOnLink);
	}

	public toggleQuantityManagerVisibility() {
		this.showQuantityManager = !this.showQuantityManager;
	}

	public updateStepperQuantity(shouldIncrement) {
		if (shouldIncrement){
			this.stepperQuantity++;
		} else {
			this.stepperQuantity--;
		}
	}

	private formatDomainNameForDisplay(domainName: string) {
		// replace @ with domain name
		domainName = domainName.replace(/^@$/, this.domainName + '.');

		// TODO: Revisit this for a refactor
		// append domain name to subdomains
		if (!domainName.includes(this.domainName)) {
			domainName = domainName.replace(/([^\.]$)/, '$1.' + this.domainName);
		}

		// strip trailing dot
		domainName = domainName.replace(/\.$/, '');

		return domainName;
	}

	public updateSubscriptionQuantity() {
		this.updateQuantityRequestState = 'loading';

		const url = this.apiService.formatter.getUrl(
			`/v1/wpcr/subscriptions/${this.subscriptionUuid}/update-quantity/${this.stepperQuantity}`
		);
		let headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		headers = headers.append('X-Organization-Id', this.accountId);
		this.apiService.http.post(url, {}, { headers }).subscribe(
			(response: any[]) => {
				this.emailAccountLimit = response['quantity'];
				this.updateQuantityRequestState = 'succeeded';
				setTimeout(() => {
					this.updateQuantityRequestState = 'inactive';
					this.showQuantityManager = false;
				}, 3000);
			},
			() => {
				this.updateQuantityRequestState = 'failed';
			}
		);
	}

	public toggleDnsRecordVisibility() {
		this.showDnsRecords = !this.showDnsRecords;
	}

	public checkDnsRecords() {
		this.showDnsRecords = false;
		this.dnsStatusState = 'loading';
		this.cpanelIpAddressRequestState = 'loading';
		this.dkimValueRequestState = 'loading';
		this.dnsRecordsRequestState = 'loading';
		this.dnsIssuesCount = 0;

		this.dnsRecords = [
			{
				description: 'Mail Exchanger (MX)',
				name: '',
				type: 'MX',
				subtype: 'MX',
				expectedValue: {
					priority: 0,
					target: 'mail.' + this.domainName,
				},
				currentValues: [],
				discrepancy: false,
			},
			{
				description: 'MX Destination Address (A)',
				name: 'mail.',
				type: 'A',
				subtype: 'A',
				expectedValue: '',
				currentValues: [],
				discrepancy: false,
			},
			{
				description: 'Sender Policy Framework (SPF)',
				name: '',
				type: 'TXT',
				subtype: 'SPF',
				expectedValue: '',
				currentValues: [],
				discrepancy: false,
			},
			{
				description: 'DomainKeys Identified Mail (DKIM)',
				name: 'default._domainkey.',
				type: 'TXT',
				subtype: 'DKIM',
				expectedValue: '',
				currentValues: [],
				discrepancy: false,
			},
			{
				description:
					'Domain-based Message Authentication, Reporting and Conformance (DMARC)',
				name: '_dmarc.',
				type: 'TXT',
				subtype: 'DMARC',
				expectedValue:
					'v=DMARC1;p=reject;sp=reject;adkim=s;aspf=s;pct=100;fo=1;rf=afrf;ri=86400',
				currentValues: [],
				discrepancy: false,
			},
		];

		// get cPanel IP address
		this.apiService
			.get('/emails/v1/cpanel/ip?username=' + this.cpanelUsername, {})
			.subscribe(
				(response) => {
					this.cpanelIpAddress = response;
					// set expected A record
					this.dnsRecords[1].expectedValue = response;

					// set expected SPF record
					this.dnsRecords[2].expectedValue =
						'v=spf1 +a +mx +ip4:' +
						response +
						' include:relay.mailchannels.net ~all';

					this.cpanelIpAddressRequestState = 'success';
					this.updateDnsStatusState();
				},
				() => {
					this.cpanelIpAddressRequestState = 'failed';
					this.updateDnsStatusState();
				}
			);

		// get expected DKIM value
		this.apiService
			.get(
				`/emails/v1/cpanel/dkim?domain=${
					this.thirdPartyDomainId ?? this.domainName
				}`,
				{}
			)
			.subscribe(
				(response) => {
					// set expected DKIM record
					this.dnsRecords[3].expectedValue =
						response['data'].payload[0].expected;

					this.dkimValueRequestState = 'success';
					this.updateDnsStatusState();
				},
				() => {
					this.dkimValueRequestState = 'failed';
					this.updateDnsStatusState();
				}
			);

		// get current DNS records
		this.apiService
			.get('/v1/dns/email?domain=' + this.domainName, {})
			.subscribe(
				(response) => {
					// set current records
					this.dnsRecords[0].currentValues = response['MX'];
					this.dnsRecords[1].currentValues = response['A'];
					this.dnsRecords[2].currentValues = response['SPF'];
					this.dnsRecords[3].currentValues = response['DKIM'];
					this.dnsRecords[4].currentValues = response['DMARC'];

					this.dnsRecordsRequestState = 'success';
					this.updateDnsStatusState();
				},
				() => {
					this.dnsRecordsRequestState = 'failed';
					this.updateDnsStatusState();
				}
			);

		// attempt to get zone file
		const url =
			Config['host'] +
			`/v1/domain-service/dns-zone/${
				this.thirdPartyDomainId ?? this.domainName
			}`;
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		this.httpClient.get(url, { headers }).subscribe(
			(response: any[]) => {
				this.dnsZoneData = response;
				this.dnsZoneRequestState = 'success';
				this.updateDnsStatusState();
			},
			(error) => {
				if (error.status === 401) {
					this.dnsZoneRequestState = 'success';
				} else {
					this.dnsZoneRequestState = 'failed';
				}
				this.updateDnsStatusState();
			}
		);
	}

	private updateDnsStatusState() {
		if (
			this.cpanelIpAddressRequestState === 'success' &&
			this.dkimValueRequestState === 'success' &&
			this.dnsRecordsRequestState === 'success' &&
			this.dnsZoneRequestState === 'success'
		) {
			if ( this.dnsRecords[0].currentValues.length > 0) {
				this.dnsRecords[0].currentValues[0].target = this.formatDomainNameForDisplay(this.dnsRecords[0].currentValues[0].target);
			}
			// compare records
			if (
				JSON.stringify(this.dnsRecords[0].currentValues[0]) !==
				JSON.stringify(this.dnsRecords[0].expectedValue)
			) {
				this.dnsRecords[0].discrepancy = true;
				this.dnsIssuesCount++;
			}

			if (
				this.dnsRecords[1].currentValues[0] !==
				this.dnsRecords[1].expectedValue
			) {
				this.dnsRecords[1].discrepancy = true;
				this.dnsIssuesCount++;
			}

			if (
				this.dnsRecords[2].currentValues.length !== 1 ||
				!this.dnsRecords[2].currentValues[0].includes(
					'+ip4:' + this.cpanelIpAddress
				) ||
				!this.dnsRecords[2].currentValues[0].includes(
					'include:relay.mailchannels.net'
				)
			) {
				this.dnsRecords[2].discrepancy = true;
				this.dnsIssuesCount++;
			}

			if (
				this.dnsRecords[3].currentValues[0] !==
				this.dnsRecords[3].expectedValue
			) {
				this.dnsRecords[3].discrepancy = true;
				this.dnsIssuesCount++;
			}

			if (!this.dnsRecords[4].currentValues.length) {
				this.dnsRecords[4].discrepancy = true;
				this.dnsIssuesCount++;
			}

			this.showDnsRecords = this.dnsIssuesCount !== 0;

			this.dnsStatusState = 'success';
		} else if (
			this.cpanelIpAddressRequestState === 'failed' ||
			this.dkimValueRequestState === 'failed' ||
			this.dnsRecordsRequestState === 'failed' ||
			this.dnsZoneRequestState === 'failed'
		) {
			this.dnsStatusState = 'error';
		}
	}

	public fixDnsIssues() {
		const newZone = [];

		// extract all non-MX, MX>A, and TXT(SPF), TXT(DKIM), TXT(DMARC) records
		this.dnsZoneData.forEach((record) => {
			if (
				record.type !== 'MX' &&
				!(record.type === 'A' && record.name === 'mail') &&
				!(
					record.type === 'TXT' &&
					record.rdata.txtValue.startsWith('"v=spf')
				) &&
				!(
					record.type === 'TXT' &&
					record.rdata.txtValue.startsWith('"v=DKIM')
				) &&
				!(
					record.type === 'TXT' &&
					record.rdata.txtValue.startsWith('"v=DMARC')
				)
			) {
				if (record.type === 'SOA') {
					// auto-increment SOA Serial
					const serial =
						new Date()
							.toISOString()
							.substr(0, 10)
							.replace(/-/g, '') + '01';
					if (parseInt(serial, 10) > record.rdata.soaSerial) {
						record.rdata.soaSerial = parseInt(serial, 10);
					} else {
						record.rdata.soaSerial =
							++record.rdata.soaSerial > 4294967295
								? 0
								: record.rdata.soaSerial;
					}
				}

				newZone.push(record);
			}
		});

		const dkimSegments =
			this.dnsRecords[3].expectedValue.match(/(.{1,255})/g);
		let dkimTxtValue = '';
		dkimSegments.forEach(function (dkimSegment) {
			dkimTxtValue += '"' + dkimSegment + '" ';
		});

		// add correct MX, MX>A, and TXT(SPF), TXT(DKIM), TXT(DMARC) records
		newZone.push({
			name: '@',
			ttl: 3600,
			type: 'MX',
			rdata: {
				mxPriority: 0,
				mxDestination: 'mail.' + this.domainName + '.',
			},
		});
		newZone.push({
			name: 'mail',
			ttl: 3600,
			type: 'A',
			rdata: { aValue: this.cpanelIpAddress },
		});
		newZone.push({
			name: '@',
			ttl: 3600,
			type: 'TXT',
			rdata: {
				txtValue:
					'"v=spf1 +a +mx +ip4:' +
					this.cpanelIpAddress +
					' include:relay.mailchannels.net ~all"',
			},
		});
		newZone.push({
			name: 'default._domainkey',
			ttl: 3600,
			type: 'TXT',
			rdata: { txtValue: dkimTxtValue.trim() },
		});
		newZone.push({
			name: '_dmarc',
			ttl: 3600,
			type: 'TXT',
			rdata: {
				txtValue:
					'"v=DMARC1;p=reject;sp=reject;adkim=s;aspf=s;pct=100;fo=1;rf=afrf;ri=86400"',
			},
		});

		// Update records in name servers
		this.fixIssuesRequestState = 'loading';
		const url = Config['host'] + '/v1/domain-service/dns-zone/';
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const body = {
			domainName: this.thirdPartyDomainId ?? this.domainName,
			zoneData: newZone,
		};
		this.httpClient.patch(url, body, { headers }).subscribe(
			(response) => {
				this.fixIssuesRequestState = 'success';
			},
			(error) => {
				this.fixIssuesRequestState = 'failed';
			}
		);
	}

	public copyValue(element) {
		element.classList.add('copy-highlight');
		this.clipboard.copy(element.innerText);
		setTimeout(() => {
			element.classList.remove('copy-highlight');
		}, 1000);
	}
}
