import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MomentModule } from 'ngx-moment';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OrganizationSettingsComponent } from './settings/settings.component';
import { OrganizationMembershipComponent } from './membership/membership.component';
import { MembershipModule } from '../membership/membership.module';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';

import { AccountService } from '../account/account.service';

import { OrganizationComponent } from './organization.component';
import { OrganizationCreateComponent } from './create/create.component';
import { LeaveOrganizationDialogComponent } from './dialogs/leave-organization-dialog/leave-organization-dialog.component';
import { FormsModule } from '@angular/forms';

import { BillingComponent } from './billing/billing.component';
import { BillingModule } from '../billing/billing.module';

import { PhoneNumberModule } from '../phone-number';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { DefaultOrgActionModule } from './default-org-action/default-org-action.module';

@NgModule({
	declarations: [
		OrganizationComponent,
		OrganizationCreateComponent,
		OrganizationSettingsComponent,
		OrganizationMembershipComponent,
		LeaveOrganizationDialogComponent,
		BillingComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		MomentModule,
		MatProgressBarModule,
		MatIconModule,
		MembershipModule,
		MatSidenavModule,
		CoreModule,
		FormsModule,
		MatListModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatSelectModule,
		MatTooltipModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatMenuModule,
		MatDialogModule,
		MatTableModule,
		BillingModule,
		DefaultOrgActionModule,
		PhoneNumberModule,
	],
    exports: [OrganizationSettingsComponent],
	providers: [AccountService],
})
export class OrganizationModule {}
