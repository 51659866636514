export class CatalogSearch {
	private monthlyCatalog;
	private yearlyCatalog;
	private activeCatalog;

	public constructor(public catalog) {
		this.yearlyCatalog = this.catalog.filter((product) => product.code !== 'wpc_project_monthly' && !product.code.match(/^(diy|managed|enterprise)_wp/));
		this.monthlyCatalog = this.catalog.filter((product) => product.code !== 'wpc_project_yearly' && !product.code.match(/^(diy|managed|enterprise)_wp/));
	}

	public findCatalogItems(products, term = 'yearly') {
		this.activeCatalog =
			'yearly' === term ? this.yearlyCatalog : this.monthlyCatalog;
			//console.log( this.activeCatalog );
		const catalogItems = [];
		for (const product of products) {
			if (/wpc_project_monthly$|wpc_project_yearly$/.test(product.code)) {
				product.code = product.code.replace(/monthly$|yearly$/, '');
				product.code += term;
			}

			const item = this.findProductFromCatalog(product.code);
			item.quantity = product.quantity;
			item.options = product.options;

			if (item) {
				catalogItems.push(item);
			}
		}
		return catalogItems;
	}

	private findProductFromCatalog(productCode) {
		// Is the product a plan?
		let product;

		if (this.activeCatalog.find((val) => val.code === productCode)) {
			product = Object.assign({}, this.activeCatalog.find((val) => val.code === productCode));

			if (product) {
				if ('recurring' === product.transaction_type) {
					product.term = {
						purchaseType: 'plan',
						planCode: product.code,
						label:
							product.plan_interval_length +
							' ' +
							product.plan_interval_unit_display,
					};
				} else {
					product.term = {
						purchaseType: 'adjustment',
						planCode: product.code,
						label: 'One-time',
					};
				}
			}
		}

		// Is the product an addon of a plan?
		if (!product) {
			for (const plan of this.activeCatalog) {
				if (plan.items && plan.items.length && plan.items.find((item) => item.code === productCode)) {
					product = Object.assign({}, plan.items.find((item) => item.code === productCode));

					if (product) {
						product.term = {
							purchaseType: 'addon',
							planCode: plan.code,
							addonCode: product.code,
							label:
								plan.plan_interval_length +
								' ' +
								plan.plan_interval_unit_display,
						};

						break;
					}
				}
			}
		}

		return product;
	}
}
