import { Injectable} from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { ConfigurationService } from '@central/ng-shared';
import { User, Billing, Payment} from '../models';
import { AmpLocalStorageService } from '../amp-local-storage-service';
import moment from 'moment';
import { Observable, throwError, forkJoin } from 'rxjs';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import _debounce from 'lodash/debounce';
import { HttpHeaders } from '@angular/common/http';
import { UserResponse, AccountInfo } from '../models/account.interface';

interface ProductAddon {
	Availability?: string;
	ProductType?: string;
	FormattedType?: string;
	[key: string]: any;
}

interface Product {
	Addons?: Record<string, ProductAddon>;
	Addon?: Record<string, ProductAddon>;
	[key: string]: any;
}

@Injectable()
export class AmpOpApiService {
	public apiUrl = '';
	public opApiUrl = '';
	public ampConnectCookie;
	public states: any = [];
	public saveRequest = 1;
	public loadedFromOrderSession = false;
	public ampCartVps = false;
	public cookieHostname = '.inmotionhosting.com';
	public saveCartDebounce: any;
	public allowedTypes = [];
	public emailAddress = null;
	public configurationCategories = [
		'Control Panel Options',
		'Operating System',
		'Optimized Configurations',
		'Hardware Firewall',
		'Memory',
		'Primary Drives',
		'Secondary Drives',
		'Bandwidth',
		'Monarx Security',
		'Remote KVM',
		'Managed Hosting'
	];
	public configProducts = ['vps','cloud','legacy','standard','commercial class','dedicated'];

	public constructor(
		public apiService: ApiService,
		public configService: ConfigurationService,
		public localStorageService: AmpLocalStorageService,
		public cookieService: CookieService
	) {
		this.apiUrl = this.configService.config.powerPanel.ampHost;
		this.opApiUrl = this.configService.config.powerPanel.opHost;
		this.cookieHostname = this.configService.config.powerPanel.cookieHostname;
		this.ampConnectCookie = this.cookieService.get('AmpConnect');

		this.saveCartDebounce = _debounce((packages: any, data: any) => {
			this.saveCartCall(packages,data);
		}, 1000);
	}

	/**
	 * Generates HTTP headers for API requests
	 * @returns {Object} Configuration object with headers and credentials
	 */
	public getHeaders() {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type','application/json; charset=utf-8')
		return {
			headers: headers,
			"withCredentials": true
		}
	}

	/**
	 * Adds new types to the allowed types cookie
	 * @param {string} type - Comma-separated string of types to allow
	 */
	public setAllowedType(type: string) {
		const ampAllowCookie = this.cookieService.get('ampallowedtypes')
		let allowedTypes = []
		if(ampAllowCookie) {
			allowedTypes = ampAllowCookie.toLowerCase().split(',')
		}
		allowedTypes = allowedTypes.concat(type.split(',').filter(e => !allowedTypes.includes(e)));
		this.setCookieData('ampallowedtypes', allowedTypes.join(','), 90);
	}

	/**
	 * Retrieves list of types that are not currently allowed
	 * @returns {string[]} Array of types that are not allowed
	 */
	public getNotAllowedTypes(): string[] {
		let notAllowedTypes = ['legacy','standard','commercial class','dedicated'];
		let allowedTypes = [];
		const ampAllowedCookie = this.cookieService.get('ampallowedtypes');
		if(ampAllowedCookie) {
			allowedTypes = ampAllowedCookie.split(',')
		}
		if(allowedTypes.includes('dedicated') || this.configService.hasFeature('ampCartDedicated') === true) {
			notAllowedTypes = [];
		}
		return notAllowedTypes.filter(e => !allowedTypes.includes(e))
	}

	/**
	 * Get Product Data Feed from Local Storage.
	 *
	 * @return Object   Data Feed.
	 */
	public getStoredDataFeed() {
		return this.localStorageService.getItem('op-data-feed');
	}

	/**
	 * Store DataFeed in Local Storage.
	 *
	 * Set TTL for DataFeed
	 * @param catalog Data Feed.
	 */
	public setStoredDataFeed(catalog) {
		catalog.ttl = moment().add(1,'days')
		this.localStorageService.setItem('op-data-feed',catalog);
		return catalog;
	}

	/**
	 * Get Product Data Feed from Local Storage.
	 *
	 * @return Object   Data Feed.
	 */
	public getCartCatalog() {
		const catalog = this.localStorageService.getItem('op-catalog');
		if(catalog !== undefined) {
			catalog['data'] = Object.keys(catalog.data).map((key) => catalog.data[key]);
			return catalog;
		} else {
			return {};
		}
	}

	/**
	 * Store DataFeed in Local Storage.
	 *
	 * Set TTL for DataFeed
	 * @param catalog Data Feed.
	 */
	public setCartCatalog(catalog) {
		const data = {
			data: catalog,
			ttl: moment().add(1,'days')
		}
		this.localStorageService.setItem('op-catalog',data);
		return data;
	}

	/**
	 * Get Data Feed Params.
	 *
	 * @return Object   Data Feed.
	 */
	public getDataFeedParams() {
		return this.localStorageService.getItem('op-data-feed-params');
	}

	/**
	 * Store DataFeed in Local Storage.
	 *
	 * Set TTL for DataFeed
	 * @param catalog Data Feed.
	 */
	public setDataFeedParams(params) {
		this.localStorageService.setItem('op-data-feed-params',params);

	}

	/**
	 * Get Cart Info from Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @return cart info.
	 */
	public getStoredCart() {
		let cart = this.localStorageService.getItem('op-cart');
		if(cart !== undefined) {
			cart = this.setCartEmail(cart);
			return cart;
		} else {
			return {};
		}
	}

	public setCartEmail(cart) {
		if(cart.hasOwnProperty('EmailAddress')){
			if(cart['EmailAddress'] !== null) {
				this.emailAddress = cart.EmailAddress
			} else {
				cart['EmailAddress'] = this.emailAddress;
			}
		} else {
			cart['EmailAddress'] = this.emailAddress;
		}
		return cart;
	}

	/**
	 * Store Cart in Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @param cart Cart info.
	 */
	public setStoredCart(cart) {
		cart.ttl = moment().add(90,'days')
		cart = this.setCartEmail(cart);
		this.localStorageService.setItem('op-cart',cart);

		this.setCookieData('cid', cart.Id, 90);
		this.setCookieData('csk', cart.CustomerSessionKey, 90);
		this.setCookieData('refid', cart.RefId, 90);
	}

	public setCookieData(cookieName, cookieObj, ttl)
    {
		const cookieHostname = this.cookieHostname;
		this.cookieService.set(cookieName, cookieObj, {
			path: '/',
			domain: cookieHostname,
			expires: ttl,
			sameSite: 'None',
			secure: true
		});
    };

	/**
	 * Get Domain Info from Local Storage.
	 *
	 * Set TTL for DomainInfo
	 * @return Domain info.
	 */
	public getStoredDomainInfo() {
		const domainInfo = this.localStorageService.getItem('op-domain');
		if(domainInfo !== undefined) {
			return domainInfo;
		} else {
			return {};
		}
	}

	/**
	 * Store DomainInfo in Local Storage.
	 *
	 * Set TTL for DomainInfo
	 * @param cart Domain info.
	 */
	public setStoredDomainInfo(domainInfo) {
		domainInfo.ttl = moment().add(90,'days')
		this.localStorageService.setItem('op-domain',domainInfo);
	}

	/**
	 * Get Account Info from Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @return cart info.
	 */
	public getStoredAccount() {
		const account = this.localStorageService.getItem('op-account');
		if(account !== undefined) {
			return account;
		} else {
			return {};
		}
	}

	/**
	 * Store Cart in Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @param cart Cart info.
	 */
	public setStoredAccount(account) {
		account.ttl = moment().add(90,'days')
		this.localStorageService.setItem('op-account',account);
	}


	public setPackageInfo(packages) {
		this.localStorageService.setItem('op-package-info',packages);
	}

	public getPackageInfo() {
		return this.localStorageService.getItem('op-package-info');
	}

	public setStoredEvents(events) {
		this.localStorageService.setItem('op-events-info',events);
	}

	public getStoredEvents() {
		const events = this.localStorageService.getItem('op-events-info');
		if(events !== undefined) {
			return events;
		} else {
			return [];
		}
	}

	public setStoredCountriesInfo(countries) {
		countries.ttl = moment().add(90,'days')
		this.localStorageService.setItem('op-countries-info',countries);
	}

	public getStoredCountriesInfo() {
		const countries = this.localStorageService.getItem('op-countries-info');
		if(countries !== undefined) {
			return countries;
		} else {
			return {};
		}
	}

	public setOrderInfo(affiliate = null, campaign = null, referrer = null) {
		const orderInfo = this.getOrderInfo();
		if(affiliate === null && orderInfo.affiliate !== null) {
			affiliate = orderInfo.affiliate;
		}
		if(campaign === null && orderInfo.campaign !== null) {
			campaign = orderInfo.campaign;
		}
		if(referrer === null && orderInfo.referrer !== null) {
			referrer = orderInfo.referrer;
		}
		const params = {
			affiliate: affiliate,
			campaign: campaign,
			referrer: referrer,
		}
		this.localStorageService.setItem('op-order-info',params);
	}

	public getOrderInfo() {
		const orderInfo = this.localStorageService.getItem('op-order-info');
		if(orderInfo) {
			return orderInfo;
		} else {
			return {
				affiliate: null,
				campaign: null,
				referrer: null,
			};
		}
	}

	/**
	 * Delete cart/datafeed info in Local Storage.
	 *
	 */
	public clearStoredInfo() {
		this.clearCartInfo()
		this.clearCatalogInfo()
		this.clearEventsInfo()
		this.localStorageService.removeItem('op-account')
		this.localStorageService.removeItem('op-order-info');
		this.localStorageService.removeItem('op-domain');
		this.localStorageService.removeItem('op-countries-info');
		const cookieHostname = this.cookieHostname;
		this.cookieService.delete('cid','/',cookieHostname);
		this.cookieService.delete('csk','/',cookieHostname);
		this.cookieService.delete('refid','/',cookieHostname);
	}

	public clearCartInfo() {
		this.localStorageService.removeItem('op-cart')
		this.localStorageService.removeItem('op-package-info')
		this.localStorageService.removeItem('op-campaign-info')
		this.localStorageService.removeItem('op-affiliate-info')
	}

	public clearCatalogInfo() {
		this.localStorageService.removeItem('op-data-feed')
		this.localStorageService.removeItem('op-data-feed-params')
		this.localStorageService.removeItem('op-catalog')
	}

	public clearEventsInfo() {
		this.localStorageService.removeItem('op-events-info')
	}

	public getOPCatalogFromCartInfo(csk, orderSession = false) {
		const vm = this;
		this.loadedFromOrderSession = orderSession;
		return new Observable((observer) => {
			if(csk) {
				vm.initializeCart(csk).subscribe(
					(cart) => {
						if(cart['success'] === true) {
							vm.setStoredCart(cart)
							const cartItemIds = Object.keys(cart['Items']);
							const packageStrings = [];
							cartItemIds.forEach(cartItemId => {
								const packageId = cart['Items'][cartItemId].PackageId;
								const productId = cart['Items'][cartItemId].ProductId;
								const packageString = packageId + '-' + productId;
								packageStrings.push(packageString)
							})
							const packageArray = this.parsePackageInfo(packageStrings.join(','))
							this.getOPCatalog(packageArray)
								.subscribe(
									(dataFeed) => {
										const data = this.filterPackagesFromCart(dataFeed,cart)
										if(data.redirect === true) {
											observer.next(data);
										} else if(data.hasOwnProperty('error')) {
											observer.next('Unable to get cart info');
											observer.error();
										} else {
											const result = {
												dataFeed: dataFeed,
												packages: data,
												packageInfo: packageArray,
												cart: cart
											}
											observer.next(result);
										}
										observer.complete();
									},
									() => {
										observer.next('Unable to get cart info');
										observer.error();
									})

						} else {
							observer.next('Unable to get cart info');
							observer.error();
						}
					},
					(error) => {
						observer.next('Unable to get cart info');
						observer.error();
					})
			} else {
				observer.next('Unable to get data feed');
				observer.error();
			}

		})

	}


	public getOPCatalogFromPackageString(packages) {
		return new Observable((observer) => {
			this.getOPCatalog(packages)
				.subscribe(dataFeed => {
					const data = this.filterPackages(dataFeed,packages);
					if(data.redirect === true) {
						observer.next(data);
					} else if(data.packageFound === true) {
						const result = {
							dataFeed: dataFeed,
							packages: data,
							packageInfo: packages,
						}
						observer.next(result);
						observer.complete();
					} else {
						observer.next('Unable to get data feed');
						observer.error();
					}
				})
		})

	}

	/**
	 * Get Raw Product Data Feed from IMHOP and Filter.
	 *
	 * Store filtered data feed in local storage.
	 *
	 * @param  packages Package and Product Info.
	 * @param  params   Affiliate and/or campaign info.
	 */
    public getOPCatalog(packages) {
		return new Observable((observer) => {
			if(packages.filter(p=>p.hasOwnProperty('packageString')). length > 0) {
				let updateDataFeed = true;
				let dataFeed = this.getStoredDataFeed();
				if (dataFeed) {
					const sameParams = this.samePackageInfo(packages);
					if(sameParams === true) {
						if(moment().toISOString() < moment(dataFeed.ttl).toISOString()) {
								updateDataFeed = false;
								observer.next(dataFeed);
								observer.complete();
						}
					} else {
						//Only use current package for now.
						// Save this for when allowing multiple
						/* const currentPackages = this.getPackageInfo();
						if(currentPackages) {
							packages = [...currentPackages,...packages];
						}
						this.setPackageInfo(packages); */
					}
				}
				this.setPackageInfo(packages);
				if(updateDataFeed) {
					const providers = [];
					for (let i = 0; i < packages.length; i++) {
						const pkg = packages[i];
						const opParams = {};
						opParams['packageId'] = pkg.packageId;
						opParams['productId'] = pkg.productId;
						opParams['includeAddons'] = true;
						const newProvider = {
							params: opParams,
						}
						providers.push(newProvider)
					}

					forkJoin(
						providers.map(p => this.getDataFeed(p.params))
					).subscribe(
						(allResults) => {
							let result = [];
							for( const i in allResults) {
								if(allResults[i].hasOwnProperty('data')) {
									result = result.concat(allResults[i]['data'].filter(
										item2 => !result.some(item1 =>
												item1.PackageId === item2.PackageId &&
												item1.PackageToProductOfferingId === item2.PackageToProductOfferingId
											)
										)
									);
								}
							}
							dataFeed = this.setStoredDataFeed({data:result})
							observer.next(dataFeed);
							observer.complete();
						},
						(resp) => {
							observer.next('Unable to get data feed');
							observer.error(resp);
						}
					)

				}
			}
		})
	}

	public parsePackageInfo(packages, domain = null) {
		const packagesArray = packages.split(',')
		return packagesArray.map(p => {
			const packageArray 	= p.split('-')
			const productId = packageArray.pop();
			const packageId = packageArray.pop();
			const result = {
				packageString: p,
				packageId: parseInt(packageId,10),
				productId: parseInt(productId,10)
			}
			if(domain) {
				result['domain'] = domain;
			}
			return result;
		})
	}

	/**
	 * Get Product Data Feed from IMHOP.
	 * @param  params   Package and Product Info. Affiliate and/or campaign info.
	 */
	public getDataFeed(params) {
		const url = this.opApiUrl + '/api/product-catalog/data-feed';
		return new Observable((observer) => {
		this.apiService.http.post(url, params, this.getHeaders())
			.subscribe(
				(catalog) => {
					observer.next(catalog);
					observer.complete();
				},
				(resp) => {
					observer.next('Unable to get data feed');
					observer.error(resp);
				}
			);
		})
	}

	/**
	 * Get Domain Data Feed from IMHOP.
	 *
	 * Store data feed in local storage.
	 *
	 * @param  packages Package and Product Info.
	 * @param  params   Affiliate and/or campaign info.
	 */
	public checkDomainInfo(domainPkg = 39) {
		return new Observable((observer) => {
			const domainInfo = this.getStoredDomainInfo();
			if(domainInfo.hasOwnProperty('data')) {
				observer.next(domainInfo);
				observer.complete();
			} else {
				const opParams = {};
				opParams['packageId'] = domainPkg;
				opParams['productName'] = 'Domain Privacy';
				opParams['includeAddons'] = true;

				const url = this.opApiUrl + '/api/product-catalog/data-feed';

				this.apiService.http.post(url, opParams, this.getHeaders())
					.subscribe(
						(catalog) => {
							this.setStoredDomainInfo(catalog);
							observer.next(catalog);
							observer.complete();
						},
						(resp) => {
							observer.next('Unable to get domain info');
							observer.error();
						}
					);
			}
		});

	}

	/**
	 * Search data feed for package info.
	 *
	 * @param packages package and product string.
	 */
	public filterPackages(dataFeed, packages) {
		let redirectToOldCart = false;
		const data = {
			packages: [],
			terms: [],
			term: "",
			packageFound: false,
			freeDomain: false,
			requiresDomain: false,
			hasConfigs: false,
			redirect: false
		};

		if (!dataFeed?.data?.length) {
			// Handle case where no datafeed exist
			return {
			  ...data,
			  error: true
			};
		}

		let selectedPackage = {}
		const selectedAddons = [];
		const selectedConfigs = [];
		const productIds = packages.map(p => p.productId);
		const notAllowedTypes = this.getNotAllowedTypes();
		const notAllowedProducts = dataFeed.data.filter(e => productIds.includes(e.PackageToProductOfferingId) && notAllowedTypes.includes(e.PackageType.toLowerCase()))
		if(notAllowedProducts.length > 0) {
			redirectToOldCart = true;
		}
		if(redirectToOldCart) {
			data.redirect = true;
			return data;
		} else {
			let selectedTerm = "";
			for (let i = 0; i < packages.length; i++) {
				const pkg = packages[i];
				const info = dataFeed.data;
				const mainProduct = info.find(e => pkg.productId === e.PackageToProductOfferingId);
				const products = info.filter(p => p.ProductName.toLowerCase().includes(mainProduct.ProductName.toLowerCase()) && parseInt(p.PackageId,10) === pkg.packageId)

				for (let n = 0; n < products.length; n++) {
					const product = products[n];
					const catalogPackage = this.createCatalogPackage(product);
					if(parseInt(product.PackageToProductOfferingId,10) === pkg.productId){
						data.packageFound = true;
						data.requiresDomain = catalogPackage['info'].PackageRequiresDomain
						data.freeDomain = catalogPackage['info'].FreeDomain
						selectedPackage = catalogPackage;
						selectedTerm = catalogPackage.planCode;
						catalogPackage['addons'].forEach(v => {
							if(v.AddonAutoSelected) {
								const addon = this.createCatalogPackage(v);
								addon['selected'] = true;
								addon['planParent'] = selectedTerm;
								selectedAddons.push(addon)
							}
						})
						if(catalogPackage.hasOwnProperty('configs')) {
							data.hasConfigs = true;
							catalogPackage['configs'].forEach(cartAddon => {
								if(cartAddon.AddonAutoSelected) {
									const config = this.createCatalogPackage(cartAddon);
									config['selected'] = true;
									config['planParent'] = selectedTerm;
									selectedConfigs.push(config)
								}
							})
							selectedPackage['selectedConfigs'] = selectedConfigs;
						}
						data.packages.push(selectedPackage)
						data.packages = [...data.packages, ...selectedAddons];
						data.packages = [...data.packages, ...selectedConfigs];
					}
					if(catalogPackage.type === 'hosting' && data.terms.filter(dt => dt.code === catalogPackage.code).length === 0) {
						data.terms.push(catalogPackage)
					}
				}
			}
			data.term = selectedTerm;
			return data;
		}
	}

	public filterPackagesFromCart(dataFeed,cartInfo) {
		let redirectToOldCart = false;
		const data = {
			packages: [],
			terms: [],
			term: "",
			packageFound: false,
			freeDomain: false,
			requiresDomain: false,
			domainName: null,
			hasDomain: false,
			hasHosting: false,
			purchasedDomain: "",
			hasConfigs: false,
			redirect: false
		}

		if (!dataFeed?.data?.length) {
			// Handle case where no datafeed exist
			return {
			  ...data,
			  error: true
			};
		}

		const selectedAddons = [];
		const selectedConfigs = [];
		let selectedTerm = "";
		const items = Object.keys(cartInfo.Items).map((key) => cartInfo.Items[key])
		const availableProducts = Object.keys(cartInfo.AvailableProducts).map((key) => cartInfo.AvailableProducts[key])
		const hostingItems = items.filter(i => availableProducts.find(e => e.Id === i.ProductId).BaseType.toLowerCase() === 'hosting')
		if(hostingItems.length > 0) {
			data.hasHosting = true;
		}
		const domainItems = items.filter(i => availableProducts.find(e => e.Id === i.ProductId).BaseType.toLowerCase() === 'domain')
		if(domainItems.length > 0) {
			data.hasDomain = true;
		}

		// Uncomment if you want to redirect to old cart
		/* if(domainItems.length > 1) {
			redirectToOldCart = true;
		} */
		const selectedPkgs = items.filter(e => !e.hasOwnProperty('PlanId'));
		const selectedPkgIds = selectedPkgs.map(e => parseInt(e.ProductId,10));
		const notAllowedTypes = this.getNotAllowedTypes();
		if(availableProducts.filter(e => selectedPkgIds.includes(parseInt(e.Id,10)) && notAllowedTypes.includes(e.SubType.toLowerCase())).length > 0) {
			redirectToOldCart = true;
		}
		if(redirectToOldCart) {
			data.redirect = true;
			return data;
		} else {
			const pkg = selectedPkgs.length > 0
			  ? selectedPkgs.reduce((latest, current) => {
				  const latestDate = new Date(latest.UpdatedAt).getTime();
				  const currentDate = new Date(current.UpdatedAt).getTime();
				  return currentDate > latestDate ? current : latest;
				})
			  : null;

			if (!pkg) {
			  // Handle case where no packages exist
			  return {
				...data,
				error: true
			  };
			}
			const info = dataFeed.data;
			const cartProduct = availableProducts.find(e => pkg.ProductId === e.Id);
			const products = info.filter(p => p.ProductName.toLowerCase().includes(cartProduct.Name.toLowerCase()) && parseInt(p.PackageId,10) === pkg.PackageId);
			for (let n = 0; n < products.length; n++) {
				const product = products[n];
				product.cartItemId = pkg['Id'];
				const catalogPackage = this.createCatalogPackage(product);
				if(catalogPackage['info'].PackageRequiresDomain) {
					data.requiresDomain = catalogPackage['info'].PackageRequiresDomain;
				}
				if(pkg.hasOwnProperty('Addon')) {
					const cartAddons = Object.keys(pkg['Addon']).map((key) => pkg['Addon'][key])
					cartAddons.forEach(cartAddon => {
						const cartAddonProduct = catalogPackage['addons'].find(p => p.AddonDetailsId === cartAddon.ProductId)
						if(cartAddonProduct) {
							cartAddonProduct.cartItemId = cartAddon['Id'];
							const addon = this.createCatalogPackage(cartAddonProduct);
							addon['selected'] = true;
							addon['planParent'] = catalogPackage['planCode'];
							addon['cartItemId'] = cartAddon['Id'];
							selectedAddons.push(addon)
						}
						if(catalogPackage.hasOwnProperty('configs')) {
							data.hasConfigs = true;
							const cartConfigProduct = catalogPackage['configs'].find(p => p.AddonDetailsId === cartAddon.ProductId)
							if(cartConfigProduct) {
								cartConfigProduct.cartItemId = cartAddon['Id'];
								const config = this.createCatalogPackage(cartConfigProduct);
								config['selected'] = true;
								config['planParent'] = catalogPackage['planCode'];
								config['cartItemId'] = cartAddon['Id'];
								selectedConfigs.push(config)
							}
						}
					})
				}
				if(pkg.hasOwnProperty('OwnedDomain') && data.domainName === null) {
					catalogPackage['options']['domain_name'] = pkg['OwnedDomain'];
					data.domainName = pkg['OwnedDomain'];
				}
				if(pkg.hasOwnProperty('PrimaryDomainId') && data.domainName === null) {
					const cartDomain = items.find(e => parseInt(e.Id,10) === parseInt(pkg.PrimaryDomainId,10));
					if(cartDomain) {
						data['purchasedDomain'] = cartDomain.Domain;
						catalogPackage['options']['domain_name'] = cartDomain.Domain;
						data.domainName = cartDomain.Domain;
					}
				}
				if(pkg.hasOwnProperty('DataCenter')) {
					catalogPackage['options']['data_center'] = pkg['DataCenter'];
				}
				if(pkg.hasOwnProperty('AutoInstall')) {
					catalogPackage['options']['autoinstall'] = pkg['AutoInstall'];
				}
				if(catalogPackage.type === 'domain') {
					catalogPackage['info'] = cartProduct;
					if(pkg.hasOwnProperty('Domain')) {
						if(data.hasHosting === false) {
							data.purchasedDomain = pkg['Domain'];
						}
						data.domainName = pkg['Domain'];
						catalogPackage['options']['domain_name'] = pkg['Domain'];
					}
				}
				catalogPackage['cartItemId'] = pkg['Id'];
				if((catalogPackage['type'] === 'hosting' && parseInt(product.PackageToProductOfferingId,10) === pkg.ProductId) || catalogPackage['type'] !== 'hosting'){
					if(catalogPackage.hasOwnProperty('configs')) {
						catalogPackage['selectedConfigs'] = selectedConfigs;
					}
					if((catalogPackage['type'] === 'hosting' && data.hasHosting === true) || (catalogPackage['type'] === 'domain' && data.hasDomain === true)) {
						data.packageFound = true;
					}
					data.packages.push(catalogPackage);
					if(selectedTerm === "") {
						selectedTerm = catalogPackage.planCode;
					}

					data.packages = data.packages.concat(selectedAddons.filter(e => e.planParent === catalogPackage.planCode));
					data.packages = data.packages.concat(selectedConfigs.filter(e => e.planParent === catalogPackage.planCode));
					data.freeDomain = catalogPackage['info'].FreeDomain;
				}
				if(catalogPackage.type === 'hosting' && data.terms.filter(dt => dt.code === catalogPackage.code).length === 0) {
					data.terms.push(catalogPackage)
				}
			}
			data.term = selectedTerm;
			return data;
		}
	}

	public createCatalogPackage(product, domain = null) {
		const price = parseFloat(product.Price) * 100;
		let termType = product.TermType;
		if(parseInt(product.TermLength,10) > 1) {
			termType += "s";
		}
		const catalogPackage = {
			"label": product.ProductName,
			"code": product.PackageId +'_'+product.PackageToProductOfferingId,
			"planCode": product.PackageId +'_'+product.PackageToProductOfferingId,
			"packageId": product.PackageId,
			"productId": product.PackageToProductOfferingId,
			"plan_interval_length": product.TermLength,
			"plan_interval_unit": termType,
			"plan_interval_unit_display": termType?.charAt(0).toUpperCase() + termType?.slice(1),
			"price_in_cents": price,
			"type": product.ProductType.split(':')[0].toLowerCase(),
			"selected": false,
		}
		if(product.hasOwnProperty('AddonDetailsId')) {
			catalogPackage['productId'] = product['AddonDetailsId'];
			catalogPackage['code'] = product.PackageId +'_'+catalogPackage['productId'];
			catalogPackage['planCode'] = product.PackageId +'_'+catalogPackage['productId'];
		}
		if(product.hasOwnProperty('cartItemId')) {
			catalogPackage['cartItemId'] = product.cartItemId;
			catalogPackage['planCode'] = catalogPackage['planCode'] + "_" + product.cartItemId;
			catalogPackage['code'] = catalogPackage['planCode'];
		}
		catalogPackage['addons'] = [];
		this.processAddons(product, catalogPackage);
		catalogPackage["info"] = product;
		catalogPackage["transaction_type"] = termType === 'one-time' ? 'one-time' : "recurring";
		catalogPackage["quantity"] = product.hasOwnProperty('quantity') ? product['quantity'] : 1;
		catalogPackage["options"] = {
			"allows_multiple": product.AllowedQuantity > 1,
			"allows_term_discount": false
		}
		if(domain) {
			catalogPackage["options"]['domain_name'] = domain;
		}
		catalogPackage["is_recurring"] = termType === 'one-time' ? false : true;
		const originalPrice = parseFloat(product.Price);
		if(product.hasOwnProperty('DiscountedPrice')) {
			product.DiscountPrice = product.DiscountedPrice;
		}
		const discountPrice = parseFloat(product.DiscountPrice);
		if(originalPrice !== discountPrice) {
			let discount = 0;
			if(discountPrice < originalPrice) {
				discount = originalPrice - discountPrice;
			}
			if(discount > 0) {
				catalogPackage['discounts'] = {
					type:"dollars",
					amount: discount
				}
			}
		}
		return catalogPackage;
	}

	private processAddons(product: Product, catalogPackage: any): void {
		try {
			const addons = product.Addons || product.Addon || {};
			const addonArray = Object.values(addons);
			addonArray.forEach(addon => {
				if (!this.isValidAddon(addon)) {
					console.warn('Invalid addon structure:', addon);
					return;
				}

				const isAvailable = !addon.Availability ||
					['both', 'op'].includes(addon.Availability);
				if (!isAvailable) return;


				const productTypeFull = (addon.ProductType || addon.FormattedType || '')
				const productType = productTypeFull.replace('Addon:', '');
				//if (this.configProducts.includes(product.PackageType.toLowerCase())) {
				if (this.configProducts.includes(product.PackageType.toLowerCase()) && this.configurationCategories.includes(productType)) {
					catalogPackage.configs = catalogPackage.configs || [];
					catalogPackage.configs.push(addon);

					if(!this.configurationCategories.includes(productType)) {
						this.configurationCategories.push(productType);
					}
				} else {
					catalogPackage.addons.push(addon);
				}

			});
		} catch (error) {
			console.error('Error processing addons:', error);
			// Consider how to handle errors - maybe set a flag on catalogPackage
			catalogPackage.hasAddonError = true;
		}
	}

	private isValidAddon(addon: ProductAddon): boolean {
		return addon !== null &&
			typeof addon === 'object' &&
			(typeof addon.ProductType === 'string' ||
			 typeof addon.FormattedType === 'string');
	}

	/**
	 * Make api call to create op cart info.
	 * Store info in local storage.
	 *
	 * @param  packages Package and Product Info.
	 * @param  params   Affiliate and/or campaign info.
	 */
	public createCart(packages) {
		const vm = this;
		const params = this.getOrderInfo();
		return new Observable((observer) => {
			const campaignHandle = params.campaign ? params.campaign : null;
			const storedCart = this.getStoredCart();
			let cartCall;
			let irid = this.cookieService.get('irid');
			if(params.affiliate) {
				irid = params.affiliate;
			}
			const items = [];
			let url;
			for(let i=0; i < packages.length; i++) {
				const itemData = {
					PackageId: packages[i].packageId,
					ProductId: packages[i].productId
				}
				if(packages[i].hasOwnProperty('domain')) {
					itemData['Domain'] = packages[i].domain;
				}
				items.push(itemData);
			}

			const cartData = {
				CampaignHandle: campaignHandle,
				ImpactRadiusId: irid,
				Items: items,
				Referrer: params.referrer
			}

			if(storedCart.hasOwnProperty('CustomerSessionKey') === false) {
				url = this.opApiUrl + '/api/cart';
				cartCall = vm.apiService.http.post(url, cartData, this.getHeaders())
				cartCall.subscribe(
					(cart) => {
						cart.Items = cartData.Items;
						vm.setStoredCart(cart);
						this.initializeCart(cart.CustomerSessionKey)
							.subscribe(
								(initCart) => {
									vm.setStoredCart(initCart)
								},
								(resp) => {
									console.log(resp)
								}
							)
						observer.next(cart)
						observer.complete()
					},
					(resp) => {
						console.log(resp)
						observer.next('failed')
						observer.error()
					}
				);
			} else {
				cartCall = this.initializeCart(storedCart.CustomerSessionKey).subscribe(
					(cart) => {
						if(cart['success'] === true) {
							vm.setStoredCart(cart);
							observer.next(cart)
							observer.complete()
						} else {
							this.clearStoredInfo()
							observer.next('failed')
							observer.error()
						}
					},
					(resp) => {
						console.log(resp)
						observer.next('failed')
						observer.error()
					})
			}
		});
	}

	/**
	 * Initializes or reinitializes a cart session with the ordering platform API
	 *
	 * @param {string} csk - Customer Session Key used to identify the cart session
	 * @returns {Observable<any>} Observable that emits the initialized cart data
	 *    Success response includes:
	 *    - success: boolean indicating if initialization succeeded
	 *    - Items: Object containing cart line items
	 *    - CustomerSessionKey: string containing the session key
	 *    - Other cart metadata
	 */
	public initializeCart(csk) {
		const url = this.opApiUrl + '/api/order/initialize';
		const data = {
			CustomerSessionKey: csk
		}
		return this.apiService.http.post(url, data, this.getHeaders())
			.pipe(
				tap(() => {
					this.fetchAmpCatalog(csk,true).subscribe();
				})
			);
	}

	/**
	 * Updates the cart data by reinitializing the cart session with the provided customer session key
	 * and storing the updated cart information.
	 *
	 * @param {string} csk - Customer Session Key used to identify and reinitialize the cart session
	 * @returns {void}
	 */
	public updateCartData(csk) {
		this.initializeCart(csk).subscribe(
			(cart) => {
			if(cart['success'] === true) {
				this.setStoredCart(cart)
			}
			},
			(error) => {
				console.log('error')
			}
		)
	}

	/**
	 * Make purchase api call to IMHOP.
	 * Build purchase request
	 *
	 * @param  data Cart and User Info.
	 * @return  Observable.
	 */
	public purchase(data) {
		const vm = this;
		const url = this.opApiUrl + '/api/order/purchase'
		const request = this.buildPurchaseRequest(data);
		return vm.apiService.http.post(url, request, this.getHeaders())
	}

	/**.
	 * Build purchase request
	 *
	 * @param  data Cart and User Info.
	 * @return  request object.
	 */
	private buildPurchaseRequest(data) {
		const matomoInfo = this.getMatomoInfo();
		let irid = this.cookieService.get('irid');
		const order = this.getOrderInfo();
		if(order) {
			if(order.affiliate) {
				irid = order.affiliate;
			}
		}
		const cart = this.getStoredCart();
		let affiliateInfo = {};
		if(irid) {
			affiliateInfo = {
				Id: irid,
				RefId: cart.RefId
			}
		}
		const request = {
			Items:              [],
			User:               <User>{},
			Billing:            <Billing>{},
			Payment:            <Payment>{},
			Affiliate:          affiliateInfo,
			CustomerSessionKey: cart.CustomerSessionKey,
			OrderSource: 'central',
			Events:             "",
			"MatomoInfo": matomoInfo
		};
		const addons = [];
		let mainItem = {};
		let domainItem = null;
		let domain = data.domainInfo.name;
		let domainPrivacy = null;
		let id = 320;
		const hostingItems = data.items.filter(item => item.type === 'hosting');
		let domainOnly = false;
		if(hostingItems.length === 0) {
			domainOnly = true;
		}
		data.items.forEach( cartItem => {
			const item = {
				Id:			id,
				ProductId:  cartItem.productId,
				Quantity:   cartItem.quantity,
			}
			if(cartItem.type === 'hosting'){
				item['PackageId'] = cartItem.packageId;
				if(data.hasOwnProperty('dataCenter')) {
					item['DataCenter'] = data.dataCenter;
				}
				domain = cartItem.domain;
				mainItem = item;
			}
			if(cartItem.type === 'domain') {
				item['Domain'] = cartItem.domain;
				if(domainOnly === false) {
					item['Free'] = data.domainInfo.freeDomain;
					item['UseDomainCredit'] = data.domainInfo.freeDomain;
				} else {
					item['PackageId'] = cartItem.packageId;
				}
				domainItem = item;
			}
			if(cartItem.label === 'Domain Privacy') {
				domainPrivacy = item;
			}
			if(cartItem.type === 'addon' && cartItem.label !== 'Domain Privacy') {
				addons.push(item)
			}
			id++;
		} );
		if(addons.length > 0) {
			mainItem['Addon'] = addons;
		}
		if(domainItem) {
			if(domainOnly === false) {
				mainItem['PrimaryDomainId'] = domainItem.Id;
				domainItem['PlanId'] = mainItem['Id'];
			}
			if(domainPrivacy) {
				domainItem['Addon'] = [domainPrivacy];
			}
		} else if(domainOnly === false) {
			mainItem['OwnedDomain'] = domain;
		}
		if(domainOnly === false) {
			request.Items.push(mainItem);
		}
		if(domainItem) {
			request.Items.push(domainItem);
		}
		let fullName;
		// User
		if(data.hasOwnProperty('user')) {
			delete request.Billing;
			const phonePrefix = data.user?.dialing_code;
			const phoneNumber = data.user?.phone;
			delete data.user.tax_id;
			request.User.FirstName        = data.user.first_name;
			request.User.LastName         = data.user.last_name;
			fullName = data.user.first_name + ' ' + data.user.last_name;
			request.User.Address1         = data.user.address1;
			request.User.Address2         = data.user.address2;
			request.User.City             = data.user.city;
			request.User.Country          = data.user.country;
			request.User.Province         = data.user.state;
			request.User.CompanyName      = data.user.company_name;
			request.User.ContactType      = '';
			request.User.ContactTypeOther = null;
			request.User.CustomerType     = data.user.customerType.toLowerCase();
			request.User.Email            = data.user.email;
			request.User.Phone            = phoneNumber;
			request.User.PhonePrefix      = phonePrefix;
			request.User.Postal           = data.user.postal_code;
			request.User.IsVat			  = false;
			request.User.IsBusiness 	  = false;
			if(data.user.hasOwnProperty('tax_id') && data.user.tax_id !== '') {
				request.User.TaxId            = data.user.tax_id;
				request.User.IsVat            = true;
				request.User.IsBusiness       = true;
			}
			request.User.Referred         = cart['Referrer'];
			request.User.SalesPersonId    = cart['SalesPersonId'];
			request.User.SalesPersonsId2  = cart['SalesPersonId2'];
			request.User.Password		  = data.user.password;
		}

		// Billing
		if(data.hasOwnProperty('billing')) {
			delete request.User;
			request.Billing.FirstName        = data.billing.first_name;
			request.Billing.LastName         = data.billing.last_name;
			fullName = data.billing.first_name + ' ' + data.billing.last_name;
			request.Billing.Address1         = data.billing.address1;
			request.Billing.Address2         = data.billing.address2;
			request.Billing.City             = data.billing.city;
			request.Billing.Country          = data.billing.country;
			request.Billing.Province         = data.billing.state;
			request.Billing.CompanyName      = data.billing.company_name;
			request.Billing.Email            = data.billing.email;
			request.Billing.Phone            = data.billing.phone;
			request.Billing.Postal           = data.billing.postal_code;
		}

		// Payment
		request.Payment.Method        = data.payment.method;
		if(data.payment.method === 'Credit Card') {
			if(data.payment.hasOwnProperty('id')) {
				request.Payment.Id = data.payment.id;
			} else {
				request.Payment.Method        = data.payment.method;
				request.Payment.Csv           = data.payment.cvv;
				request.Payment.Expiry        = data.payment.expiration;
				request.Payment.Name      	  = fullName;
				request.Payment.Number        = data.payment.card_number;
			}
		}
		if(data.payment.method === 'PayPal') {
			if(data.payment.hasOwnProperty('id')) {
				request.Payment.Id = data.payment.id;
			}
		}
		const events = this.getStoredEvents();
		events[0]['CustomerSessionKey'] = cart.CustomerSessionKey;
		const billingEvent = {
			updateEvent: true,
			AccountId: "",
			event: "cart:billing",
			timeStamp: Date.now()
		}
		if(request.hasOwnProperty('User')) {
			billingEvent['AccountId'] = request.User.hasOwnProperty('AccountId') ? request.User['AccountId'] : "";
			billingEvent['User'] = {...request.User};
			if(billingEvent['User'].hasOwnProperty('Password')) {
				delete billingEvent['User']['Password'];
			}
		}
		if(request.hasOwnProperty('Billing')) {
			billingEvent['Billing'] = {...request.Billing};
			if(billingEvent['Billing'].hasOwnProperty('Password')) {
				delete billingEvent['Billing']['Password'];
			}
		}

		events.push(billingEvent);
		request.Events = JSON.stringify(events);
		return request;
	}

	/**
	 * Make authentication api call to IMHOP.
	 * Should get cookie
	 *
	 * @param  data Cart and User Info.
	 * @return  Observable.
	 */
	public cartAuthenticate = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				password: data.password,
			}

			const url = this.opApiUrl + '/api/user/authenticate'
			vm.apiService.http.post(url, request, this.getHeaders())
				.subscribe(resp => {
					if(resp.hasOwnProperty('authenticated')) {
						if(resp.authenticated === true) {
							if(resp.hasOwnProperty('access-token')) {
								const ttl = new Date();
								ttl.setTime(
									ttl.getTime() + 15*60000 /* 30 minutes in milliseconds */
								);
								this.setCookieData('AmpConnect', resp['access-token'], ttl);
							}
							this.ampConnectCookie = this.cookieService.get('AmpConnect');
							if(cart?.hasOwnProperty('CustomerSessionKey')) {
								this.updateCartData(cart.CustomerSessionKey)
							}
							observer.next();
							observer.complete();
						} else {
							observer.error('failed');
						}
					} else {
						observer.error('failed');
					}
				})

		})
    }

	/**
	 * Make has-two-factor api call to IMHOP.
	 *
	 * @param  data email.
	 * @return  Observable.
	 */
	public checkTwoFactor = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				CustomerSessionKey: cart.CustomerSessionKey
			}

			const url = this.opApiUrl + '/api/user/has-two-factor'
			vm.apiService.http.post(url, request, this.getHeaders())
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp.hasOwnProperty('hasTwoFactor')) {
						if(['text','dormant'].includes(resp['type'])) {
							const codeRequest = {
								email: data.email,
								type: resp['type'],
								accountId: resp['accountId']
							}
							if(resp['type'] === 'dormant') {
								codeRequest['send'] = false;
								codeRequest['comment'] = true
							}
							this.sendVerificationCode(codeRequest).subscribe()
						}
						observer.next(resp)
						observer.complete();

					} else {
						observer.error(resp);
					}
				})

		})
    }

	/**
	 * Make verify-two-factor api call to IMHOP.
	 *
	 * @param  data email.
	 * @return  Observable.
	 */
	public sendVerificationCode = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				accountId: data.accountId,
				CustomerSessionKey: cart.CustomerSessionKey
			}

			let path = '/api/user/send-sms-auth'
			if(data['type'] === 'dormant') {
				path = '/api/user/send-one-time-code';
			}
			const url = this.opApiUrl + path;
			vm.apiService.http.post(url, request, this.getHeaders())
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp['status'] === '200') {
						observer.next(resp)
						observer.complete();
					} else {
						observer.error(resp);
					}
				})

		})
    }

	/**
	 * Adds a new credit card to the user's account
	 *
	 * @param data - The credit card data to be added
	 * @param {Object} data.Method - Payment method (e.g. 'Credit Card')
	 * @param {string} data.Number - Credit card number
	 * @param {string} data.Csv - Card security code (CVV)
	 * @param {string} data.Expiry - Card expiration date
	 * @param {string} data.Name - Cardholder name
	 *
	 * @returns Observable<any> - Returns an observable that emits the API response
	 *   Success: Emits the response object and completes
	 *   Error: Emits the error through the error channel
	 */
	public addNewCard = function (data) {
		const vm = this;
		return new Observable((observer) => {
			const url = this.opApiUrl + '/api/new-card/undefined'
			vm.apiService.http.post(url, data, this.getHeaders())
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					observer.next(resp)
					observer.complete();
				},
				(error) => {
					observer.error(error);
				})
		})
	}

	/**
	 * Make verify-two-factor api call to IMHOP.
	 *
	 * @param  data email.
	 * @return  Observable.
	 */
	public verifyTwoFactor = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				code: data.code,
				CustomerSessionKey: cart.CustomerSessionKey
			}

			const url = this.opApiUrl + '/api/user/verify-two-factor'
			vm.apiService.http.post(url, request, this.getHeaders())
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp['success'] === true) {
						observer.next(resp)
						observer.complete();
					} else {
						observer.next()
						observer.error();
					}
				},
				(error) => {
					observer.error(error);
				})

		})
    }

	/**
	 * Make forgot-password api call to IMHOP.
	 *
	 * @param  data email and username/domain.
	 * @return  Observable.
	 */
	public forgotPassword = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const request = {
				email: data.email,
				username: data.username,
				brand: this.configService.config.powerPanel.brandShortName,
			}

			const url = this.opApiUrl + '/api/cart/forgot-password'
			vm.apiService.http.post(url, request, this.getHeaders())
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp.success === true) {
						observer.next(resp)
						observer.complete();
					} else {
						observer.error({error: resp.errors[0]});
					}
				})

		})
    }

	public isAuthenticated = function() {
		if(this.ampConnectCookie) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Make forgot-password api call to IMHOP.
	 *
	 * @param  data email and username/domain.
	 * @return  Observable.
	 */
	public getBilling = function (id) {
        const vm = this;
		return new Observable((observer) => {
			const request = {
				PaymentId: id,
			}

			const url = this.opApiUrl + '/api/user/get-billing'
			vm.apiService.http.post(url, request, this.getHeaders())
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(typeof resp === 'object' && resp.hasOwnProperty('Billing')) {
						observer.next(resp['Billing'])
						observer.complete();
					} else {
						observer.error(resp);
					}
				})

		})
    }

	/**
	 * Transforms the API user response into the internal AccountInfo format
	 * @param resp - The raw user response from the API
	 * @returns AccountInfo - The transformed account information
	 * @throws Error if the response format is invalid
	 */
	private transformUserResponse(resp: UserResponse): AccountInfo {
		// Validate input
		if (!resp?.User) {
			throw new Error('Invalid user response format');
		}

		// Safely split location codes with defaults
		const [countryCode = '', country = ''] = resp.User.Country?.split(':') || [];
		const [stateCode = '', state = ''] = resp.User.Province?.split(':') || [];

		return {
			user: {
			first_name: resp.User.FirstName || '',
			last_name: resp.User.LastName || '',
			address1: resp.User.Address1 || '',
			address2: resp.User.Address2 || '',
			city: resp.User.City || '',
			countryCode: countryCode.trim(),
			country: country.trim(),
			stateCode: stateCode.trim(),
			state: state.trim(),
			company_name: resp.User.CompanyName || '',
			email: resp.User.Email || '',
			phone: resp.User.Phone || '',
			postal_code: resp.User.Postal || '',
			zip: resp.User.Postal || '',
			},
			payments: Array.isArray(resp.Payment?.MethodsAvailable)
			? resp.Payment.MethodsAvailable
			: [],
			accountId: resp.User.AccountId || '',
			currency: resp.Currency || 'USD' // Added default currency
		};
	}

	public getAccountInfo(cached = false): Observable<AccountInfo> {
		return new Observable((observer) => {
		  // Early return if not authenticated
		  if (!this.isAuthenticated()) {
			this.removeAuthInfo();
			observer.error('not authenticated');
			return;
		  }

		  const cart = this.getStoredCart();
		  const storedAccount = this.getStoredAccount();

		  // Return cached data if requested and available
		  if (cached && storedAccount?.user) {
			observer.next(storedAccount);
			observer.complete();
			return;
		  }

		  // Fetch fresh data
		  this.apiService.http.post(
			`${this.opApiUrl}/api/user/get-account`,
			{},
			this.getHeaders()
		  )
		  .pipe(catchError(error => throwError(() => error)))
		  .subscribe({
			next: (resp: UserResponse) => {
			  if (!resp.User) {
				this.removeAuthInfo();
				observer.error('not authenticated');
				return;
			  }

			  const account = this.transformUserResponse(resp);
			  this.setStoredAccount(account);

			  if (cart?.CustomerSessionKey) {
				this.updateCartData(cart.CustomerSessionKey);
			  }

			  observer.next(account);
			  observer.complete();
			},
			error: () => {
			  this.removeAuthInfo();
			  observer.error('not authenticated');
			}
		  });
		});
	}

	public removeAuthInfo() {
		const cookieHostname = this.cookieHostname;
		this.cookieService.delete('AmpConnect','/',cookieHostname);
		this.ampConnectCookie = null;
		this.localStorageService.removeItem('op-account')
	}

	public logout() {
		const url = this.opApiUrl + '/api/user/logout';
		return new Observable((observer) => {

			this.apiService.http.post(url, this.getHeaders())
				.subscribe ( resp => {
					this.removeAuthInfo();
					observer.next('logged out')
					observer.complete();
				})
			});
	}

	private samePackageInfo = function(packageInfo) {
		const storedPackageInfo = this.getPackageInfo();
		return _isEqual(storedPackageInfo, packageInfo)
	}

	public countryLookup(code = null) {
		const vm = this;
		let updateCountries = false
		return new Observable((observer) => {
			const countries = this.getStoredCountriesInfo();
			let url = this.opApiUrl + '/api/country-lookup'
			if(code) {
				if(countries.hasOwnProperty('countries')) {
					const codeIndx = countries.countries.findIndex(e => e.Code === code);
					if(codeIndx > -1) {
						if(countries.countries[codeIndx].hasOwnProperty('States') && countries.countries[codeIndx]['States'].length > 0) {
							updateCountries = false;
							observer.next(countries.countries[codeIndx])
							observer.complete()
						} else {
							updateCountries = true;
							url += '/' + code;
						}
					}
				}
			} else {
				if(countries.hasOwnProperty('ttl')) {
					if((moment().toISOString() > moment(countries.ttl).toISOString())) {
						updateCountries = true;
					}
				} else {
					updateCountries = true;
				}
			}
			if(updateCountries === true) {
				vm.apiService.http.get(url, this.getHeaders())
					.subscribe(
					(resp) => {
						if(resp.hasOwnProperty('States')) {
							const countryIndx = countries.countries.findIndex(e => e.Code === code);
							if(countryIndx > -1) {
								countries.countries[countryIndx] = resp;
								this.setStoredCountriesInfo(countries);
							}
							this.states = resp;
							observer.next(resp)
							observer.complete()
						} else {
							if(resp.hasOwnProperty('_embedded')) {
								if(resp['_embedded'].hasOwnProperty('country')) {
									const newCountries = resp['_embedded']['country'];
									if(newCountries && newCountries.length > 0) {
										this.setStoredCountriesInfo({countries: newCountries});
									}
								}
							}
							observer.next(countries);
							observer.complete();
						}
					},
					(resp) => {
						console.log(resp)
						observer.next('failed')
						observer.error(resp)
					}
				);
			} else {
				observer.next(countries)
				observer.complete()
			}
		})

	}

	public fetchAmpCatalog = function(csk = null, force = false) {
		return new Observable((observer) => {
			const host = this.opApiUrl;
			const url = host + '/api/order/fetch-catalog';
			let updateCartCat = true;
			const cart = this.getStoredCart();
			if(cart && csk === null) {
				csk = cart.CustomerSessionKey;
			}
			let cartCat = this.getCartCatalog();
			if(cartCat.hasOwnProperty('data') && cart.hasOwnProperty('ttl')) {
				if((moment().toISOString() < moment(cart.ttl).toISOString())) {
					updateCartCat = false;
				}
			}
			if(force === true) {
				updateCartCat = true;
			}
			if(updateCartCat === true) {
				const request = {
					CustomerSessionKey: csk,
				};
				this.apiService.http.post(url, request, this.getHeaders())
					.subscribe(resp => {
						if(resp.hasOwnProperty('success')) {
							if(resp.success === true) {
								cartCat = this.setCartCatalog(resp['AvailableProducts']);
								cartCat.data = Object.keys(cartCat.data).map((key) => cartCat.data[key]);
								observer.next(cartCat);
								observer.complete()
							}
						}
					})
			} else {
				observer.next(cartCat);
				observer.complete()
			}
		})
	}

	public checkAvailability = function(domain, v2 = true) {
		const host = this.opApiUrl;
		let url = host + '/api/registrar-service/check-availability';
		if(v2 === true) {
			url += '-v2';
		}
		url += '?domain=' + domain;
		return this.apiService.http.get(url, this.getHeaders())
	}

	public checkEmail = function(email) {
		const host = this.opApiUrl;
		const cart = this.getStoredCart();
		let csk = '';
		const unixDate = Date.now();
		if(cart) {
			csk = cart.CustomerSessionKey;
		}
		const url = host + '/api/user/check-email';
		return this.apiService.http.post(url, {email: email, CustomerSessionKey: csk, timestamp: unixDate}, this.getHeaders())
	}

	public addToEvents(event,data) {
		const events = this.getStoredEvents();
		const storedCart = this.getStoredCart();
		let csk = null;
		if(storedCart.hasOwnProperty('CustomerSessionKey')) {
			csk = storedCart.CustomerSessionKey;
		}
		const newEvent =  {
			event: event,
			timeStamp: Date.now(),
		}
		if(event === 'cart:advance') {
			newEvent['from'] = data.from;
			newEvent['to'] = data.to;
			if(data['initial'] === true) {
				newEvent['CustomerSessionKey'] = csk;
			}
		}

		events.push(newEvent);
		this.setStoredEvents(events);
	}


	public saveCart(packages,data = {}) {
		if(packages) {
			this.saveCartDebounce(packages,data);
		}
	}

	public saveCartCall(packages,data) {
		const matomoInfo = this.getMatomoInfo();
		const events = this.getStoredEvents();
		const cart = this.getStoredCart();
		if(cart.hasOwnProperty('CustomerSessionKey')) {
			const newItems = this.buildCartItems(packages,cart)
			if(data.hasOwnProperty('email')) {
				cart.EmailAddress = data['email']
			}
			const request = {
				"CustomerSessionKey": cart.CustomerSessionKey,
				"Request": this.saveRequest,
				"Items": newItems,
				"Email": cart.EmailAddress,
				"Events": JSON.stringify(events),
				"MatomoInfo": matomoInfo
			}
			const host = this.opApiUrl;
			const url = host + '/api/order/save';
			this.apiService.http.post(url, request, this.getHeaders())
				.subscribe(resp => {
					cart['Items'] = resp['Items'];
					this.setStoredCart(cart);
					this.saveRequest = this.saveRequest +1;
				})
		}
	}

	public buildCartItems(data, cart) {
		let tempId = 124;
		let items = [];
		const addonItems = data.filter(e => e.type === 'addon');
		if(!cart.hasOwnProperty('Items') || cart['Items'] === null) {
			cart['Items'] = {};
		}
		const savedCartItems = Object.keys(cart.Items).map((key) => cart.Items[key]);
		const hostingItems = data.filter(e => e.type === 'hosting');
		hostingItems.forEach(hosting => {
			const hostingAddons = [];
			const item = {
				Id:			'c' + tempId.toString(),
				ProductId:  hosting.productId,
				Quantity:   hosting.quantity,
				OwnedDomain: hosting.options.domain_name,
				DataCenter: hosting.options.data_center
			}
			const savedCartItem = savedCartItems.find(e => parseInt(e.ProductId,10) === parseInt(hosting.productId,10))
			if(hosting.hasOwnProperty('cartItemId')) {
				item.Id = hosting.cartItemId
			} else if(savedCartItem) {
				item.Id = savedCartItem.Id
			} else {
				tempId++;
			}
			addonItems.forEach(addon => {
				if(!addon.label.includes('Domain')) {
					const addonItem = {
						Id:			'c' + tempId.toString(),
						ProductId:  addon.productId,
						Quantity:   addon.quantity,
					}
					if(savedCartItem) {
						if(savedCartItem.hasOwnProperty('Addon')){
							if(typeof savedCartItem.Addon === 'object') {
								savedCartItem.Addon = Object.keys(savedCartItem.Addon).map((key) => savedCartItem.Addon[key])
							}
							const savedAddon = savedCartItem.Addon.find(e => parseInt(e.ProductId,10) === parseInt(addon.productId,10))
							if(savedAddon) {
								addonItem.Id = savedAddon.Id
							}
						}
					}
					if(addonItem.Id === "c" + tempId.toString()) {
						tempId++;
					}
					hostingAddons.push(addonItem)
				}
			})
			if(hostingAddons.length > 0) {
				item['Addon'] = hostingAddons;
			}
			items.push(item)
		})
		const domainItems = data.filter(e => e.type === 'domain');
		domainItems.forEach(domain => {
			let freeDomain = false;
			const domainAddons = [];
			if(domain.hasOwnProperty('discounts') && domain.discounts !== undefined) {
				if(domain.discounts.amount * 100 === domain.price_in_cents) {
					freeDomain = true;
				}
			}
			const item = {
				Id:			'c' + tempId.toString(),
				ProductId:  domain.productId,
				Quantity:   domain.quantity,
				Domain: domain.options.domain_name,
				PlanId: null,
				Free: freeDomain,
			}
			const savedCartItem = savedCartItems.find(e => parseInt(e.ProductId,10) === parseInt(domain.productId,10))
			if(savedCartItem) {
				item.Id = savedCartItem.Id
			} else {
				tempId++;
			}
			const possibleParentItems = items.filter(e => e.hasOwnProperty('DataCenter') && e.hasOwnProperty('OwnedDomain') )
			if(possibleParentItems) {
				const parentItem = possibleParentItems.find(e => e.OwnedDomain === item.Domain)
				if(parentItem) {
					item.PlanId = parentItem.Id;
					parentItem.PrimaryDomainId = item.Id;
					delete parentItem.OwnedDomain;
					items = items.map((itemInfo) => itemInfo.id === parentItem.Id ? parentItem : itemInfo);
				}
			}
			addonItems.forEach(addon => {
				if(addon.label.includes('Domain')) {
					const addonItem = {
						Id:			'c' + tempId.toString(),
						ProductId:  addon.productId,
						Quantity:   addon.quantity,
						ProductClass: "Domains"
					}
					if(savedCartItem) {
						if(savedCartItem.hasOwnProperty('Addon')) {
							if(typeof savedCartItem.Addon === 'object') {
								savedCartItem.Addon = Object.keys(savedCartItem.Addon).map((key) => savedCartItem.Addon[key])
							}
							const savedAddon = savedCartItem.Addon.find(e => parseInt(e.ProductId,10) === parseInt(addon.productId,10))
							if(savedAddon) {
								addonItem.Id = savedAddon.Id
							}
						}
					}
					if(addonItem.Id === "c" + tempId.toString()) {
						tempId++;
					}
					domainAddons.push(addonItem)
				}
			})
			if(domainAddons.length > 0) {
				item['Addon'] = domainAddons;
			}
			items.push(item)
		})
		return items;
	}

    /**
     * Extracts and flattens all addon products from a catalog
     *
     * @param catalog - The product catalog object containing a data array of products
     * @returns An array of addon products extracted from all products in the catalog
     *
     */
	public getCatalogAddons(catalog) {
		// Return empty array if catalog or data is invalid
		if (!catalog?.data?.length) {
			return [];
		}

		// Use reduce instead of imperative loop
		return catalog.data.reduce((addons, product) => {
			if (product?.Addon) {
				// Convert object to array and concat with existing addons
				const productAddons = Object.values(product.Addon);
				return [...addons, ...productAddons];
			}
			return addons;
		}, []);
	}

	public getMatomoInfo() {
		const matomoInfo = {
			cartUrl: window.location.href,
			visitorId: null
		}
		if(window.hasOwnProperty('Matomo')) {
			matomoInfo.visitorId = window['Matomo'].getTracker().getVisitorId()
		}
		return matomoInfo;
	}

	public getGoogleEnhancedECommercePayload(data,cart, orderInfo, catalog) {
		const items = [];
		let affiliate = "";
		let campaign = "";
		if(orderInfo) {
			if(orderInfo.hasOwnProperty('affiliate')) {
				affiliate = orderInfo.affiliate;
			}
			if(orderInfo.hasOwnProperty('campaign')) {
				campaign = orderInfo.campaign;
			}
		}
		const catalogAddons = this.getCatalogAddons(catalog) || [];

		let packageName = "";
		for (let i = 0; i < cart.cart.length; i++) {
			const cartItem = cart.cart[i];
			let catalogItem = catalog?.data?.find(e => e.Id === cartItem.productId);
			if(catalogItem === undefined) {
				catalogItem = catalogAddons.find(a => a.Id === cartItem.productId)
			}
			if(catalogItem) {
				if(packageName === "") {
					packageName = catalogItem.PackageName;
				}

				const item = {
					item_id: cartItem.productId,
					item_name: catalogItem.ProductClass + " - " + catalogItem.Name + " - " + catalogItem.ProductTerm,
					affiliation: catalogItem.Brand,
					coupon: packageName, // campaign
					discount: catalogItem.DiscountAmount, //discount for item
					index: i, //item index
					item_brand: catalogItem.Brand,
					item_category: catalogItem.BaseType, // type
					item_category2: catalogItem.SubType, // secondary type
					item_category3: catalogItem.Name, // product name
					item_category4: catalogItem.ProductTerm, // term_length
					item_list_id: this.isAuthenticated() ? "authenticated" : 'unauthenticated',
					item_variant: false, // ExitIntentDiscount true/false,
					location_id: "ChIJVVbosh_AuokR9gnIrZKnLtQ",
					price: catalogItem.DiscountedPrice, // product price,
					quantity: cartItem.quantity,
					promotion_id: affiliate ? affiliate : "", // affiliates
					promotion_name: campaign ? campaign : cartItem.info.CampaignName, // campaign
				}
				items.push(item)
			}
		}

		const payload =  {
			event_action: 'purchase',
			send_to: "",
			ecommerce: {
				transaction_id: "T_" + data['ReceiptId'],// receiptId ???
				value: parseFloat(data['Total']).toFixed(2),
				tax: parseFloat(data['Taxes']).toFixed(2),
				coupon: packageName,
				currency: cart.currency,
				items: items
			}
		}
		return payload;
	}

	public conditionCheck(product, addon, itemAddons) {
        const conditional = addon['Conditional'];
        const triggerTestResults = {};
		const conditionalKeys = Object.keys(conditional);
		for ( let i=0; i<conditionalKeys.length; i++ ) {
			const triggerName = conditionalKeys[i];
			const trigger = conditional[triggerName]
            if ((trigger.availableTo === 'all')||
                ((product.hasOwnProperty('Upsell') && product['Upsell'] === true) && (trigger.availableTo === 'upsell')) ||
                ((!product.hasOwnProperty('Upsell') || product['Upsell'] === false) && (trigger.availableTo === 'non-upsell'))) {
                triggerTestResults[triggerName] = {};
				const triggerRules = Object.keys(trigger.rules)
				for ( let ruleIdx=0; ruleIdx<triggerRules.length; ruleIdx++ ) {
					const ruleType = triggerRules[ruleIdx];
					const rule = trigger.rules[ruleType]
                    if (ruleType === 'DoesNotInclude') {
                        triggerTestResults[triggerName][ruleType] = {};
						const ruleKeys = Object.keys(rule);
						for ( let ruleKeyIdx=0; ruleKeyIdx<ruleKeys.length; ruleKeyIdx++ ) {
							const ruleKey = ruleKeys[ruleKeyIdx]
							const ruleItem = rule[ruleKey];
                            triggerTestResults[triggerName][ruleType] = true;
							if(itemAddons.filter(e => ruleItem.map(r => r.toLowerCase()).includes(e.hasOwnProperty('TermType') && e.ProductName.toLowerCase() + " - " + e.TermLength + " " + e.TermType.toLowerCase())).length > 0) {
								triggerTestResults[triggerName][ruleType] = false;
							}
                       }
                    } else if (ruleType === 'Includes') {
                        triggerTestResults[triggerName][ruleType] = {};
						const ruleKeys = Object.keys(rule);
						for ( let ruleKeyIdx=0; ruleKeyIdx<ruleKeys.length; ruleKeyIdx++ ) {
							const ruleKey = ruleKeys[ruleKeyIdx]
							const ruleItem = rule[ruleKey];
                            triggerTestResults[triggerName][ruleType] = false;
							if(itemAddons.filter(e => ruleItem.map(r => r.toLowerCase()).includes(e.hasOwnProperty('TermType') && e.ProductName.toLowerCase() + " - " + e.TermLength + " " + e.TermType.toLowerCase())).length > 0) {
								triggerTestResults[triggerName][ruleType] = true;
							}
                        }
                    }
                    if (ruleType === 'IsNot') {
                        triggerTestResults[triggerName][ruleType] = {};
						const ruleKeys = Object.keys(rule);
						for ( let ruleKeyIdx=0; ruleKeyIdx<ruleKeys.length; ruleKeyIdx++ ) {
							const ruleKey = ruleKeys[ruleKeyIdx]
							const ruleItem = rule[ruleKey];
                            triggerTestResults[triggerName][ruleType] = true;
							if(ruleItem.includes(product.ProductTerm)) {
								triggerTestResults[triggerName][ruleType] = false;
							}
                       }
                    } else if (ruleType === 'Is') {
                        triggerTestResults[triggerName][ruleType] = {};
						const ruleKeys = Object.keys(rule);
						for ( let ruleKeyIdx=0; ruleKeyIdx<ruleKeys.length; ruleKeyIdx++ ) {
							const ruleKey = ruleKeys[ruleKeyIdx]
							const ruleItem = rule[ruleKey];
                            triggerTestResults[triggerName][ruleType] = false;
							if(ruleItem.includes(product.ProductTerm)) {
								triggerTestResults[triggerName][ruleType] = true;
							}
                        }
                    }
                    if (ruleType === 'DoesNotHaveCookie') {
                        triggerTestResults[triggerName][ruleType] = {};
						const ruleKeys = Object.keys(rule);
						for ( let ruleKeyIdx=0; ruleKeyIdx<ruleKeys.length; ruleKeyIdx++ ) {
							const ruleKey = ruleKeys[ruleKeyIdx]
							const ruleItem = rule[ruleKey];
							const cookie = this.cookieService.get(ruleKey)
							triggerTestResults[triggerName][ruleType] = true;
							if(cookie && cookie === ruleItem) {
								triggerTestResults[triggerName][ruleType] = false;
							}
                       }
                    } else if (ruleType === 'HasCookie') {
                        triggerTestResults[triggerName][ruleType] = {};
						const ruleKeys = Object.keys(rule);
						for ( let ruleKeyIdx=0; ruleKeyIdx<ruleKeys.length; ruleKeyIdx++ ) {
							const ruleKey = ruleKeys[ruleKeyIdx]
							const ruleItem = rule[ruleKey];
							const cookie = this.cookieService.get(ruleKey)
							triggerTestResults[triggerName][ruleType] = false;
							if(cookie && cookie === ruleItem) {
								triggerTestResults[triggerName][ruleType] = true;
							}

                        }
                    }
                }
            }
        }
		return triggerTestResults;
    }

	public ruleCheck(rules, invert) {
		for ( const rule in rules ) {
			if(rules.hasOwnProperty(rule)) {
				if (rules[rule] === false) {
					if (invert) {
						return true;
					} else {
						return false;
					}
				} else if (rules[rule] === true) {
					if (invert) {
						return false;
					} else {
						return true;
					}
				} else {
					for(const ruleIdx in rules[rule]){
						if(rules[rule].hasOwnProperty(ruleIdx)) {
							const ruleResult = rules[rule][ruleIdx]
							if (invert) {
								if (ruleResult === true) {
									return false;
								}
							} else {
								if (ruleResult === false) {
									return false;
								}
							}
						}
					}
				}
				return true;
			}
		}
		return true;
    }

	public isConditionallyAllowed(product, addon, itemAddons) {
		const conditional = addon['Conditional'];
		const result  = this.conditionCheck(product,addon, itemAddons);
		let passed = true;
		if(Object.keys(result).length > 0) {
			// Process Rules
			passed = false;
			for ( const trigger in result ) {
				if(result.hasOwnProperty(trigger)) {
					const invert = conditional[trigger].hide;
					const ruleCheckResult = this.ruleCheck(result[trigger], invert);
					if (ruleCheckResult === true) {
						passed = true;
					} else if (conditional[trigger].mustMatch) {
						return false;
					}
				}
			}
		} else {
			passed = true;
		}
		return passed;
    }

    public isConditionallyRequired(product, addon, itemAddons) {
		const productAvailability = this.isConditionallyAllowed(product, addon, itemAddons)
		let isRequired = false;
		if(productAvailability) {
			const conditional = addon['Conditional'];
			if(conditional) {
				const result  = this.conditionCheck(product,addon, itemAddons);
				if(Object.keys(result).length > 0) {
					// Process Rules
					isRequired = false;
					for ( const trigger in result ) {
						if(result.hasOwnProperty(trigger)) {
							const invert = conditional[trigger].hide;
							const ruleCheckResult = this.ruleCheck(result[trigger], invert);
							if (ruleCheckResult === true) {
								isRequired = true;
							}
						}
					}
				} else {
					isRequired = false;
				}
			}
		}
		return isRequired;
    }

	public getAvailableAddons(product, itemAddons) {
        const availableAddons = [];
        if (product) {
            let productAddons   = product.hasOwnProperty('Addon') ? product.Addon : product.hasOwnProperty('Addons') ? product.Addons : [];
			productAddons = Object.keys(productAddons).map((key) => productAddons[key]);
			for( const addon of productAddons) {
				if(addon.hasOwnProperty('Conditional')) {
					const result  = this.isConditionallyAllowed(product,addon, itemAddons);
					if (result) {
						if(availableAddons.filter( e => e.Id === addon.Id).length === 0) {
							availableAddons.push(addon)
						}
						if(addon.hasOwnProperty('Children')) {
							const childAddons = Object.keys(addon.Children).map((key) => addon.Children[key]);
							for(const child of childAddons) {
								let childAvailability = false;
								if(child.hasOwnProperty('Conditional')) {
									const childResult  = this.isConditionallyAllowed(addon,child, itemAddons);
									if(childResult) {
										childAvailability = true;
									}
								} else {
									childAvailability = true;
								}
								if(childAvailability) {
									if(availableAddons.filter( e => e.Id === child.Id).length === 0) {
										availableAddons.push(child)
									}
								}
							}
						}
					}
				} else {
					if(availableAddons.filter( e => e.Id === addon.Id).length === 0) {
						availableAddons.push(addon)
					}
					if(addon.hasOwnProperty('Children')) {
						const childAddons = Object.keys(addon.Children).map((key) => addon.Children[key]);
						for(const child of childAddons) {
							if(availableAddons.filter( e => e.Id === child.Id).length === 0) {
								availableAddons.push(child)
							}
						}
					}
				}
			}


		}
        return availableAddons;
    }

}
