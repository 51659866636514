import { AfterViewInit, Component, HostBinding, Input, ViewChild } from '@angular/core';
import intlTelInput from 'intl-tel-input';
import { ConfigurationService } from '../../core';

@Component({
	selector: 'central-phone-number',
	templateUrl: './phone-number.component.html',
	styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent implements AfterViewInit {
	@ViewChild('phone') public phoneInput;
	@Input() public isRequired = false;

	@HostBinding('class.contact_form')
	@Input() public inContactForm = false;

	@Input() public readOnlyForm = false;
	@Input() public disableForm = false;
	@Input() public showLabel = false;
	private _value;

	public iti;
	public errorMessage;

	constructor(public configurationService: ConfigurationService) {}

	public ngAfterViewInit() {
		this.setupPhone();
	}
	get value() {
		return this._value;
	}
	@Input() set value(value: string) {
		if( ! this.iti ) {
			this._value = value;
		} else {
			this.setNumber(value);
		}
	}
	public setNumber(value) {
		this._value = value;
		this.iti.setNumber(value || '');
	}
	public setupPhone() {
		if( ! this.phoneInput?.nativeElement ) {
			setTimeout(() => this.setupPhone(), 100);
			return;
		}
		this.iti = intlTelInput(this.phoneInput.nativeElement, {
			utilsScript:
				'https://inmotionhosting.github.io/static-assets/js/phone-utils.js',
		});
		this.iti.setNumber(this._value || '');
	}

	public getNumber(includePrefix = true) {
		let number = this.iti.getNumber(
			window['intlTelInputUtils'].numberFormat.E164
		);
		if (!includePrefix) {
			const dialCode = this.iti.selectedCountryData.dialCode;
			number = number.replace("+"+dialCode, "");
		} else {
			const dialCode = this.iti.selectedCountryData.dialCode;
			number = number.replace("+"+dialCode, "+"+dialCode+".");
		}
		return number;
	}

	public isValid() {
		this.errorMessage = '';

		if (!this.isRequired && !this.getNumber()) {
			return true;
		}

        if (!this.getNumber()) {
			this.errorMessage = 'A phone number is required.';
		}

		if (!this.iti.isValidNumber()) {
			this.errorMessage =
				'Please enter a valid phone number including area code.';
		}

		return this.iti.isValidNumber();
	}
}
