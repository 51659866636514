import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { ApiService, AuthService, ConfigurationService, PollingService, ProfileService } from '@central/ng-shared';
import { AddMemberDialogComponent } from 'libs/ng-shared/src/lib/membership/dialogs/add-member-dialog/add-member-dialog.component';

import { WpRestService } from '../environment/services/wordpress.service';
import { AppService } from '../../app.service';
import { ProjectService } from '../project.service';

import { ProjectDetailsDialogComponent } from './project-details-dialog/project-details-dialog.component';
import { ConnectKeyDialogComponent } from './connect-key/connect-key-dialog/connect-key-dialog.component';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
import { EditDialogComponent } from '../../shared/edit-dialog/edit-dialog.component';
import { LicenseService } from '../../shared/license/license.service';

@Component({
	selector: 'project-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
	public status = 'loading';
	public requestState: string;

	public connectKey;
	public ownerAccount;
	public isAnOrg = false;
	public billingSubscription;
	public currentUserRole = 'member';
	public openingChat = false;

	public environments = [];
	public stats = [];

	public addVpsState = 'pending';

	public vpsStatus = 'loading';
	public vpsServers = [];
	public vpsServersNotReady = [];
	public vpsStates: string[] = [];
	public vpsStats: string[] = [];

	public addPlaygroundState = [];
	public addPlaygroundTitle = 'Playground 1';

	public playgroundStatus = 'loading';
	public playgroundAvailabilityRequestState;
	public displayedColumns = ['name', 'actions'];
	public playgroundLimit;
	public playgroundUsed;
	public playgrounds;

	public playbookStatus = 'complete';

	private subscriptions = [];

	constructor(
		public router: Router,
		public appService: AppService,
		private dialog: MatDialog,
		private apiService: ApiService,
		private authService: AuthService,
		public profileService: ProfileService,
		public httpClient: HttpClient,
		public projectService: ProjectService,
		private licenseService: LicenseService,
		private snackBar: MatSnackBar,
		public configService: ConfigurationService,
		public pollingService: PollingService,
		public wpRestService: WpRestService,
		public sanitizer: DomSanitizer,
	) {}

	public ngOnInit() {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
                this.licenseService.onReady().subscribe(() => {
					this.stats = this.licenseService.getAccountLicenses();
					this.connectKey = this.licenseService
						.getLicenses()
						['connect_keys'].find(
							(key) =>
								+key.connect_id ===
								+this.projectService.project.fields.connect_id
						);

					this.ownerAccount = {
						account_id: this.projectService.project?.fields?.organization_id,
					};
					this.isAnOrg =
						this.projectService.project?.fields.organization_id !==
						this.authService.getAccountId();

                    this.getVpsAvailability();

                    this.getBillingSubscription();
					this.getPlaygroundAvailability(this.projectService.project);
					this.setNewPlaygroundTitle();

					this.subscriptions.push(
						this.projectService.getPlaybookStatus.subscribe((data: string) => {
							this.playbookStatus = data;
						})
					)

					if (new Date(this.appService.get('project')[0].created_at).getTime() > Date.now() - 10000) {
						this.openProjectDetailsDialog(true)
					}
				});
			})
		)
	}

	ngOnDestroy(): void {
		this.pollingService.stopPoll();
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	// Events emitted from header component
	public header(event: string) {
		switch (event) {
			case 'edit':
			case 'edit-name':
				this.openProjectDetailsDialog(false);
				break;
			case 'invite':
				this.openAddDialog();
				break;
			case 'key':
				this.openConnectKeyDialog();
				break;
			case 'delete':
				this.openDeleteProjectDialog();
				break;
		}
	}

	private getPlaygroundAvailability(project) {
		this.playgrounds = project.children.filter(
			(playgroundEnv) =>
				playgroundEnv.fields.environment_type !== 'vps' &&
				playgroundEnv.fields.environment_type !== 'remote' &&
				playgroundEnv.fields.site_url.match(
					/(demo3\.(cloudwp\.dev|playgrounds\.cloud)|demo-dev-new\.boldgrid\.com|demo-dev\.boldgrid\.com)/
				)
		);

		// Allow additional playgrounds if within limit
		while (this.playgrounds.length < 3) {
			this.playgrounds.push({ create: true });
		}

		// Establish pending playground states
		this.playgrounds.forEach(() => {
			this.addPlaygroundState.push('pending')
		})

		this.playgroundStatus = 'ready';

		// get account playground limit
		this.apiService
			.get('/v1/licenses/playground-limits', {
				headerOptions: {
					xOrgId: this.ownerAccount.account_id,
				},
			})
			.subscribe(
				(licenses: any) => {
					const playground = licenses.filter(
						(license) => license.id === this.ownerAccount.account_id
					)[0];
					this.playgroundLimit = playground.limit;
					this.playgroundUsed = playground.used;
					this.playgroundAvailabilityRequestState = 'success';
				},
				() => {
					// TODO Push to rollbar
					setTimeout(() => this.getPlaygroundAvailability(project), 3000);
				}
			);
	}

    private getVpsAvailability() {
        this.vpsServersNotReady = this.projectService.servers.filter(
            (vpsEnvironment) => vpsEnvironment.fields.has_publish_setup_user !== true
        );

        if (this.vpsStatus !== 'ready') {
            this.projectService.reloadProject().subscribe(() => {
                if (this.projectService.servers.length) {
                    this.setVpsStates();
                }
            })
        } else {
            return this.vpsServersNotReady.length;
        }

		if ((this.vpsStatus !== 'ready' &&
			this.billingSubscription !== 'expired') ||
            !this.billingSubscription) {
			this.subscriptions.push(
				this.pollingService
					.startPoll(
						() => this.projectService.reloadProject(),
						() => this.getVpsAvailability() === 0,
						{ interval: 20000, timeout: 900000 }
					)
					.subscribe(
						() => {
							this.setVpsStates();
						},
						(error) => {
							this.projectService.rollbarService.rollbar.warn(error);
						}
				)
			)
        }

        return this.vpsServersNotReady.length;
	}

    private setVpsStates() {
		// TODO Improve upon this for multi-server
		const index = 0;

		if (!this.projectService.servers[index]?.fields?.machine_details?.veid) {
			this.vpsStates[index] = 'provisioning';
		} else {
			this.vpsStates[index] = 'pending';
		}

		this.projectService.getScreenshot(
			this.projectService.project,
			this.projectService.servers[index]
		)

		this.subscriptions.push(
			this.projectService.getServerInfo.subscribe(
				(serverInfo) => {
					if (serverInfo) {
                        this.vpsStates[index] = 'ready';

						if (serverInfo['stoppedby'] && serverInfo['stoppedby'] === 'Systems') {
							this.vpsStates[index] = 'suspended';
						}
                        if (serverInfo['response'] && serverInfo['response'].includes('does not exist')) {
							this.vpsStates[index] = 'reclaimed';
						}
						if (!this.projectService.servers[index].fields?.has_publish_setup_user) {
							this.vpsStates[index] = 'installing';
						}

						// If server doesn't return 'running' as status it should be stopped.
						if ('running' !== serverInfo['status']) {
							this.vpsStats[index] = 'stopped';
						} else {
							this.vpsStats[index] = 'running';
						}
					}
				})
		)

		this.vpsStatus = 'ready';
	}

	public changeEnvironment(environmentId: string) {
		this.projectService.setEnvironment(environmentId);
		this.router.navigate(
			[
				'/projects',
				this.projectService.project.id,
				this.projectService.environment.fields?.environment_usage === 'unknown' ? 'setup' : 'overview'
			],
			{ queryParams: { environment_id: environmentId } }
		);
	}

	public openDialog(environmentId: string) {
		const env = this.projectService.project.children.find(
			(val) => val.id === environmentId
		);

		const ref = this.dialog.open(DeleteDialogComponent, {
			disableClose: true,
			width: '500px',
			data: {
				label: 'Environment',
				requireNameConfirmation: true,
				warning: env.fields.cloud_id
					? 'Deleting this environment will also permanently delete the WordPress install and all its data.'
					: '',
				nameConfirmation: env.label,
				apiCall: '/v1/environments/' + environmentId,
				apiData: {},
			},
		});

		ref.componentInstance.deleteSuccess.subscribe(() => {
			this.projectService.appService.refreshScreenshot(
				this.projectService.project.id, environmentId
			)
			this.projectService.reloadProject().subscribe((project) => {
				this.getPlaygroundAvailability(project);
			});
		});
	}

	public openEditDialog(env: any, isNew = false): void {
		const updateAction = (component) => {
			const url = this.apiService.formatter.getUrl(
				'/v1/environments/' + env.id
			);
			const headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});

			this.httpClient
				.patch(url, { label: env.label }, { headers })
				.subscribe(
					(response: any) => {
						this.projectService
							.reloadProject()
							.subscribe(() => {
								env.label = response.label.trim();
								component.dialogRef.close();
								component.snackBar.open(
									'Rename Successful',
									'',
									{
										duration: 3000,
									}
								);
							});
						this.projectService.fetchProjects(false).subscribe();
					},
					() => (component.state = 'failed')
				);
		};

		const dialogRef = this.dialog.open(EditDialogComponent, {
			width: '500px',
			disableClose: true,
			data: {
				isNew,
				resource: env,
				resourceItem: 'label',
				updateAction,
			},
		});
	}

	public save() {
		this.status = 'submitted';

		const url = this.apiService.formatter.getUrl(
			'/v1/projects/' + this.projectService.project.id
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.httpClient
			.patch(
				url,
				{
					label: this.projectService.project.label,
					description: this.projectService.project.description,
					fields: {
						mode: 'advanced',
					},
				},
				{ headers }
			)
			.subscribe(
				(projectUpdated) => {
					this.status = 'success';
					this.projectService.project.label = projectUpdated['label'];
					this.projectService.project.fields =
						projectUpdated['fields'];

					this.snackBar.open('Updated Successfully!', '', {
						duration: 3000,
						horizontalPosition: 'right',
					});
				},
				() => {
					this.status = 'failed';
				}
			);
	}

	openAddDialog(membership?: any) {
		const dialogRef = this.dialog.open(AddMemberDialogComponent, {
			disableClose: true,
			width: '500px',
			data: {
				resourceType: 'organizations',
				resourceId: this.projectService.project.fields.organization_id,
				email: membership?.['invite_email'],
				project: true,
				ownerId: this.projectService.project.fields.organization_id,
				ownerName: this.getOwnerName(this.projectService.project),
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.snackBar.open(
						'Invitation e-mail sent successfully!',
						'',
						{
							duration: 4000,
						}
					);
				}
			},
		});
	}

	openProjectDetailsDialog(isNew) {
		const dialogRef = this.dialog.open(ProjectDetailsDialogComponent, {
			...this.configService.config.dialog,
			data: {
				isNew,
				resourceType: 'organizations',
				resourceId: this.projectService.project.fields.organization_id,
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.snackBar.open(
						'Project details successfully updated!',
						'',
						{
							duration: 4000,
						}
					);
				}
			},
		});
	}

	public addPlayground(index) {
		if (this.addPlaygroundState[index] === 'submitted') {
			return;
		}

		if (this.playgrounds.filter(env => env.id).length >= 3) {
			return;
		}

		/*
		if (this.playgroundUsed >= this.playgroundLimit) {
			this.dialog.open(CatalogDialogComponent, {
				...this.configService.config.dialog,
				data: {
					title: 'Choose a Plan',
					tags: ['cloud-wordpress'],
					notice: true,
					team: this.projectService.project.fields.organization_id,
					limit: this.playgroundLimit,
                    hasVps: this.projectService.servers.length,
                    projectId: this.projectService.project.id,
				},
			});

			return;
		}
		*/

		let newPlayground;
		this.addPlaygroundState[index] = 'submitted';
		this.projectService
			.addEnvironment({
				label: this.addPlaygroundTitle,
				project: this.projectService.project,
			})
			.subscribe({
				next: (env) => {
					newPlayground = env;
				},
				complete: () => {
					this.addPlaygroundState[index] = 'pending';
					this.projectService.reloadProject().subscribe((project: any) => {
						// Prompt user for customization of playground label
						const newEnvironment = project.children.find(child => child.id === newPlayground.id);
						this.openEditDialog(newEnvironment, true);

						this.getPlaygroundAvailability(project);
						this.setNewPlaygroundTitle();

						// Refresh projects cache
						this.projectService.fetchProjects(false).subscribe();
					});
				},
				error: (error) => {
					this.addPlaygroundState[index] = 'pending';
					console.log(error);
				},
			});
	}

	/**
	 * Checks existing titles, and prepares the new title for playgrounds.
	 */
	public setNewPlaygroundTitle() {
		if (!this.playgrounds.length) {
			return;
		}

		this.addPlaygroundTitle = this.appService.generateName('environment');
		return;

		let title = this.addPlaygroundTitle;
		const result = this.playgrounds
			.map(({ label }) => label)
			.filter((label) => label?.includes(title));

		// Loop through all titles for matches, and append N + 1
		for (let i = 0; i < result.length; i++) {
			if (result.includes(title)) {
				// regexr: https://regexr.com/77qo7  - match My Project 1, My Project 38748, ect.
				title =
					title.replace(/(\s\d+)(?!.*\s\d)/, '') +
					' ' +
					Math.abs(i + 1);
				this.addPlaygroundTitle = title;
			}
		}
	}

	/**
	 * Open delete dialog
	 * @param proj a Project with ID and label to delete.
	 */
	public openDeleteProjectDialog() {
		// Check if project has active subscriptions.
		// Open dialog.
		const ref = this.dialog.open(DeleteDialogComponent, {
			...this.configService.config.dialog,
			data: {
				label: 'Project',
				error:
					this.billingSubscription && this.billingSubscription?.match(/(active|comp)/)
						? `This project cannot be deleted because it has active subscriptions. You can manage your subscriptions for this project under your <a href="/team/${this.projectService.project.fields.organization_id}/subscriptions" class="notranslate">Team\'s Settings</a>`
						: '',
				requireNameConfirmation: true,
				warning:
					'Deleting this project will also delete any related environments.',
				nameConfirmation: this.projectService.project.label,
				apiCall: '/v1/projects/' + this.projectService.project.id,
				apiData: {},
			},
		});

		ref.componentInstance.deleteSuccess.subscribe(() => {
			this.projectService.fetchProjects(false).subscribe(() => {
				this.licenseService.fetch(false);
				this.router.navigateByUrl('/projects');
			});
		});
	}

	public openConnectKeyDialog() {
		this.apiService
			.get(
				'/v1/connect-keys/' +
					this.projectService.project.fields.connect_id,
				{}
			)
			.subscribe((response: any) => {
				const ref = this.dialog.open(ConnectKeyDialogComponent, {
					...this.configService.config.dialog,
					data: {},
				});
				const instance = ref.componentInstance;
				instance.connectId =
					this.projectService.project.fields.connect_id;
				instance.connectKey = response.key;
			});
	}

	public getBillingSubscription(checkVps = true) {
		this.projectService.getBillingSubscription.subscribe({
			next: (subscription) => {
				if (subscription) {
					this.billingSubscription = subscription['state'];

					const orphanedServers = [];

					if (this.billingSubscription === 'expired') {
						this.projectService.servers.forEach((server, index) => {
							orphanedServers.push(server);
							this.vpsStates[index] = this.billingSubscription;
						});
					}

					if (orphanedServers.length) {
						this.projectService.rollbarService.rollbar
							.critical('This project has an active VPS yet an expired subscription');
					}

					if (this.billingSubscription === 'active' && this.projectService.servers.length === 0) {
						this.projectService.rollbarService.rollbar
							.critical('This project has an active subscription, yet no VPS was assigned');
					}
				}
			},
			error: (error) => {
				if (error.error.message === 'No subscription found.') {
					// Check if project has complimentary VPS
					if (this.projectService.project.fields?.coupon_code?.includes('partner')) {
						this.requestState = 'success';
						this.billingSubscription = 'comp';
						if (checkVps) { this.setVpsStates(); }
					} else {
						this.requestState = 'success';
						this.billingSubscription = false;
						this.projectService.rollbarService.rollbar
							.critical('This project has an active vps yet a subscription could not be identified');
						if (checkVps) { this.setVpsStates(); }
					}
				} else {
					this.requestState = 'failed';
					this.billingSubscription = 'error';
					this.projectService.rollbarService.rollbar
						.critical('An error occured retrieving subscription information');
				}
				this.vpsStatus = 'ready';
			},
		});
	}

	public getOwnerName(project) {
		const access = this.profileService.data.account_access.find(
			(accessed) => accessed.account_id === project.fields.organization_id
		);

		let name = this.profileService.data.display_name || 'You';
		if (access) {
			name = access.display_name;
		}

		return name;
	}

	openChat() {
		this.openingChat = true;
		if (window['zE'] && window['zE'].widget) {
			if (window['zE'].widget === 'classic') {
				window['zE']('webWidget', 'show');
				window['zE']('webWidget', 'open');
			}
			if (window['zE'].widget === 'messenger') {
				window['zE']('messenger', 'show');
				window['zE']('messenger', 'open');
			}
			setTimeout(() => (this.openingChat = false), 2000);
		}
	}

	openAdmin(environmentId: string) {
		let login = true;
		this.projectService.setEnvironment(environmentId);

		if (this.projectService.project.fields.project_type === 'wordpress') {
			const wpAdmin = this.projectService.onClientConnected;
			wpAdmin.subscribe((msg: any) => {
				if (msg === 'ready') {
					if (environmentId === this.wpRestService.getSite(this.projectService.environment)?.environment?.id) {
						if (login) {
							this.projectService.login('wp-admin/');
							login = false;
						}
					}
				}
			}).unsubscribe();
		}
	}

	sanitizeUrl(url): SafeHtml {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
