import { Injectable, EventEmitter, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../../../core/api/api.service';
import { Gravitar } from '../../../core/gravitar/gravitar';
import { LocalStorageService } from '../../../core/local-storage/local-storage.service';
import { AuthService } from '../auth.service';
import { Observable, ReplaySubject } from 'rxjs';
import { Format } from '../../../core/api/formatter';
import { HttpClient } from '@angular/common/http';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfigurationService } from '../../../core/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';

interface IProfileData {
	image?: string;
	account_name?: string;
	account_id?: number;
	username?: string;
	email?: string;
	display_name?: string;
	onboarded?: boolean;
	type?: string;
	redirect_url?: string;
	is_disabled?: false;
	account_access: any[];
	requires_email_verification?: boolean;
	notifications?: number;
	reward_points?: number;
	completed_onboarding?: boolean;
	options?: any;
	tester: boolean;
	trial_available: boolean;
	currency: string;
	locale: string;
}

@Injectable()
export class ProfileService {
	public profileChange = new EventEmitter();
    public preProfileUpdate = new ReplaySubject();
    public onProfileUpdate = new ReplaySubject(1);
	public fetching = false;
	public data: IProfileData;
	public gravitar: Gravitar;
	public hasRedirect = false;
	public firebaseUser: any;

	private debounceTime = 60;
	private lastUpdated = 0;

	private defaultProfile = {
		image: 'assets/img/icons/person_outline-24px.svg',
		account_name: '',
		username: '',
		onboarded: false,
		type: '',
		account_access: [],
		email: '',
		display_name: '',
		redirect_url: '',
		requires_email_verification: false,
		notifications: 0,
		reward_points: 0,
		options: {},
		tester: false,
		trial_available: false,
		currency: '',
		locale: '',
	};

	constructor(
		private apiService: ApiService,
		public authService: AuthService,
		private localStorage: LocalStorageService,
		private configService: ConfigurationService,
		private httpClient: HttpClient,
		private ngZone: NgZone,
		private snackBar: MatSnackBar,
		private router: Router,
		private cookieService: CookieService
	) {
		this.gravitar = new Gravitar();
		this.data = { ...this.defaultProfile };

		// Set the default value for provisioning.
		this.data.options['internal_feature_provisioning'] =
			this.configService.config.features.provisioning;
		this.authService.onLogin.subscribe(() => this.fetch('signin'));
		this.authService.onLogout.subscribe(() => this.reset());
		this.authService.onInit.subscribe(() => this.fetch());

		this.router.events.subscribe((navigationEnd: NavigationEnd) => {
			if (navigationEnd instanceof NavigationEnd) {
				if ( navigationEnd?.urlAfterRedirects !== '/logout' ) {
					this.updateProfileCheck();
				}

				this.handleRedirectUrl(navigationEnd.url);
			}
		});
	}

	/**
	 * Unset the user profile.
	 *
	 * @since 1.17.0
	 */
	public reset() {
		this.localStorage.removeItem('tester');
		this.data = { ...this.defaultProfile };
		this.data.image = this.gravitar.getDefaultUrl();
		this.lastUpdated = 0;
		this.profileChange.emit();
	}

	/**
	 * An observable that will resolve when the data is ready.
	 *
	 * @since 1.23.0
	 */
	public onReady() {
		return new Observable((observer: any) => {
			const resolver = () => {
				if (this.lastUpdated) {
					observer.next();
					observer.complete();
				} else {
					observer.error('On Ready failed');
				}
			};

			if (this.fetching) {
				this.profileChange.subscribe(() => resolver());
			} else {
				resolver();
			}
		});
	}

	/**
	 * Figure out which profile image to use.
	 *
	 * @since 1.17.0
	 *
	 * @return Image URL.
	 */
	public determineProfileImage() {
		if (this.data.image) {
			return this.data.image;
		}

		// If profile image from provider.
		const image = this.firebaseUser?.photoURL
			? this.firebaseUser.photoURL
			: this.gravitar.getUrl(this.data.email);

		return image;
	}

	/**
	 * Make an API call to get profile data.
	 *
	 * @since 1.17.0
	 *
	 * @param source Source of call.
	 */
	public fetch(source?: string) {
		if (!this.authService || !this.authService.isLoggedIn()) {
			return;
		}

		this.fetching = true;
		this.preProfileUpdate.next();

		this.apiService.get('getProfile', {}).subscribe(
			(profile: any) => {
				this.updateProfileData(profile);

				// User signed in and called fetch profile.
				if ('signin' === source && this.data.redirect_url) {
					this.hasRedirect = true;
				}

                this.onProfileUpdate.next(this.data);

				this.fetching = false;
			},
			() => {
                this.profileChange.emit();
				this.fetching = false;
			}
		);
	}

	/**
	 * Setter for auth service.
	 *
	 * @since 1.17.0
	 *
	 * @param  authService Auth Service.
	 */
	public setAuthService(authService: AuthService): void {
		this.authService = authService;
	}

	/**
	 * Update a user profile.
	 */
	public update(options: object) {
		this.data.options = { ...this.data.options, ...options };

		return new Observable((observer: any) => {
			const format = new Format(this.configService.config);
			const url = format.getUrl('getProfile');
			const headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});

			this.httpClient.post(url, options, { headers }).subscribe(
				(response) => {
					this.updateProfileData(response);

					observer.next(response);
					observer.complete();
				},
				(error) => {
					observer.error(error);
				}
			);
		});
	}

	/**
	 * Update service values for profile.
	 *
	 * @param profile Profile data to store.
	 */
	private updateProfileData(profile: any) {
		if (
			true === this.configService.config.features.notifications &&
			!this.data.notifications &&
			profile.notifications
		) {
			let horizontalPosition: MatSnackBarHorizontalPosition = 'right';
			if (this.configService.config.brandConfig.id === 'imh') {
				horizontalPosition = 'center';
			}
			const snackBarRef = this.snackBar.open(
				'New notification!',
				'View',
				{
					duration: 5000,
					horizontalPosition,
					panelClass: ['mat-toolbar', 'mat-accent'],
				}
			);

			snackBarRef.onAction().subscribe(() => {
				this.router.navigateByUrl('/account/notifications');
			});
		}

		const mergeData = () => {
			this.data = { ...this.defaultProfile, ...profile };
			this.lastUpdated = this.getTime();
			this.data.account_name =
				this.getAccountNameOverride();
			this.data.image = this.determineProfileImage();
		};

		// This needs some work...I want components to wait for this information to be ready
		// but not have to wait until google responded.
		if (!this.lastUpdated) {
			mergeData();
		}

		this.ngZone.run(
			async () =>
				new Promise<void>((resolve) => {
					this.authService.firebase.authReady
						.asObservable()
						.subscribe((user) => {
							this.firebaseUser = user;
							mergeData();
							this.setUserPreferences();
							this.profileChange.emit();
							this.onProfileUpdate.next(this.data);
							resolve();
						});
				})
		);
	}

	/**
	 * Get the account name override.
	 *
	 * @since 1.17.0
	 *
	 * @return string
	 */
	private getAccountNameOverride(): string {
		return !this.data.email &&
			this.firebaseUser &&
			this.firebaseUser.displayName
			? this.firebaseUser.displayName
			: this.data.account_name;
	}

	/**
	 * Update profile information every 3 minutes as long as the user is using the app.
	 *
	 * On page navigation, if it's been more than 3 minutes since we updates
	 * the profile, update it.
	 *
	 * @since 1.17.0
	 */
	private updateProfileCheck() {
		if (this.lastUpdated) {
			if (this.lastUpdated + this.debounceTime < this.getTime()) {
				this.fetch();
			}
		}
	}

	/**
	 * Get the current time.
	 *
	 * @since 1.17.0
	 * @return Time in seconds.
	 */
	private getTime(): number {
		return new Date().getTime() / 1000;
	}

	/**
	 * This should currently only handle users who need to activate their cloud WordPress.
	 * It's a bit hacky. I'd like this removed.
	 *
	 * @since 1.17.0
	 */
	private handleRedirectUrl(url: string) {
		if (this.hasRedirect) {
			/*
			 * If we navigate to any page that's not the reset password page.
			 * This can only happen once per central load
			 */
			if (-1 === url.indexOf('update-password')) {
				this.hasRedirect = false;

				if (
					this.localStorage.getItem('confirmationCode') &&
					this.data.redirect_url
				) {
					this.router.navigateByUrl(this.data.redirect_url);
				}
			}
		}
	}

	/**
	 * Check for salesperson ID cookie and make sure not already set in profile
	 */
	public saveSalespersonTracking() {
		if (
			this.cookieService.check('spt')
		) {
			this.update({
				public_salesperson_tracking: this.cookieService.get('spt'),
			}).subscribe();
		}
	}

	/**
	 * Check for Impact partner cookie and make sure not already set in profile
	 */
	public saveImpactPartnerTracking() {
		if (
			this.cookieService.check('irid')
		) {
			this.update({
				public_impact_partner_id: parseInt(
					this.cookieService.get('irid'),
					10
				),
			}).subscribe();
		}
	}

	/**
	 * Set data based on user settings
	 */
	public setUserPreferences() {
		if (this.configService.config.brandConfig.id === 'imh') {
			// Allows user to experience unreleased features
			this.localStorage.setItem('tester', this.data.options['public_beta_tester'] || false);

			// Overrides language inheritance from MKTG Site with saved preference
			if (window['Weglot'] && this.data.options['public_preferred_language']) {
				window['Weglot'].switchTo(this.data.options['public_preferred_language']);
			}

			// Sets the user's default team
			const teams = this.data.account_access.filter((team) => team.type === 'organization');
			if (!this.data.options['public_selected_team']) {
				this.data.options['public_selected_team'] = teams[0].account_id;
				if (!this.data.options['public_selected_team'] ||
					this.data.options['public_selected_team'] === this.data.account_access[0].account_id) {
					this.data.options['public_selected_team'] = this.data.account_access[1].account_id
				}

				this.localStorage.setItem('team', teams[0].display_name)
				setTimeout(() => this.update({ public_selected_team: this.data.options['public_selected_team'] }).subscribe(),
					5000);
			} else {
				const activeTeam =
					teams.find((team) => team.account_id === this.data.options['public_selected_team']) || teams[0]
				this.localStorage.setItem('team', activeTeam?.display_name);
			}
		}
	}
}
