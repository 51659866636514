<mat-progress-bar
	*ngIf="(this.configurationService.config.brandConfig.id === 'imh' && 'loading' === status) || (this.configurationService.config.brandConfig.id !== 'imh' && 'loading' === state)"
	mode="indeterminate" color="{{ this.configurationService.config.buttonColor || '' }}"></mat-progress-bar>

<div
	[hidden]="this.configurationService.config.brandConfig.id === 'imh' && 'loading' === status && 'loading' === state">
	<form #form="ngForm" (ngSubmit)="form.valid && submit()" class="form" *ngIf="'loading' !== state" [class]="
            this.configurationService.config.brandConfig.id === 'imh'
                ? 'animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-2xl ctw-mt-32 ctw-mb-16'
                : ''
        ">
		<div class="ctw-mb-10">
			<ng-container *ngIf="this.configurationService.config.brandConfig.id !== 'imh'">
				<h2>
					{{
					this.configurationService.config.groupLabels[
					'group-label-title-case'
					]
					}}
					Profile
				</h2>
				<p>
					Update your
					{{
					this.configurationService.config.groupLabels[
					'group-label-lower-case'
					]
					}}
					contact information.
				</p>
			</ng-container>
			<ng-container *ngIf="this.configurationService.config.brandConfig.id === 'imh'">
				<h2>
					Contacts
				</h2>
				<p>
					We'll use the following information when we need to communicate with your team.
				</p>
			</ng-container>
		</div>
		<mat-card class="ctw-mb-5" *ngIf="!this.accountService.hasAccess('write')">Member read only access</mat-card>
		<mat-form-field appearance="{{
                this.configurationService.config.inputAppearance || fill
            }}" [class.ctw-mb-0]="this.configurationService.config.brandConfig.id === 'imh'">
			<mat-label>{{
				this.configurationService.config.groupLabels[
				'group-label-title-case'
				]
				}}
				Name</mat-label>
			<input matInput [(ngModel)]="organizationName" [disabled]="!this.accountService.hasAccess('write')" required
				#name="ngModel" name="organizationName" placeholder="Your Organization Name" />
			<mat-error>
				<ng-container *ngIf="name.errors?.required">This field is required.</ng-container>
			</mat-error>
		</mat-form-field>
		<ng-container *ngIf="!this.websiteOptional && !this.configurationService.config.brandConfig.id === 'imh'">
			<mat-form-field appearance="{{
                    this.configurationService.config.inputAppearance || fill
                }}" [class.ctw-mb-0]="this.configurationService.config.brandConfig.id === 'imh'">
				<mat-label>Website</mat-label>
				<input matInput required (blur)="this.fixUrls()" type="url"
					[disabled]="!this.accountService.hasAccess('write')" centralValidationUrl [(ngModel)]="orgWebsite"
					required #website="ngModel" name="orgWebsite" placeholder="www.corporate-website.com" />
				<mat-error>
					<ng-container *ngIf="website.errors?.required">This field is required.</ng-container>
					<ng-container *ngIf="!website.errors?.required">Invalid URL format.</ng-container>
				</mat-error>
			</mat-form-field>
		</ng-container>
		<ng-container *ngIf="this.websiteOptional && !this.configurationService.config.brandConfig.id === 'imh'">
			<mat-form-field appearance="{{
                    this.configurationService.config.inputAppearance || fill
                }}" [class.ctw-mb-0]="this.configurationService.config.brandConfig.id === 'imh'">
				<mat-label>Website</mat-label>
				<input matInput (blur)="this.fixUrls()" type="url" [disabled]="!this.accountService.hasAccess('write')"
					centralValidationUrlOptional [(ngModel)]="orgWebsite" #website="ngModel" name="orgWebsite"
					placeholder="www.corporate-website.com" />
				<mat-error>
					<ng-container *ngIf="website.errors?.required">This field is required.</ng-container>
					<ng-container *ngIf="!website.errors?.required">Invalid URL format.</ng-container>
				</mat-error>
			</mat-form-field>
		</ng-container>

		<mat-form-field appearance="{{
                this.configurationService.config.inputAppearance || fill
            }}" [class.ctw-mb-0]="this.configurationService.config.brandConfig.id === 'imh'">
			<mat-label>Contact Email</mat-label>
			<input [(ngModel)]="contactEmail" [disabled]="!this.accountService.hasAccess('write')" email type="email"
				#contact="ngModel" required matInput name="contactEmail" placeholder="engineering@company.com" />
			<mat-error>
				<ng-container *ngIf="contact.errors?.required">This field is required.</ng-container>
				<ng-container *ngIf="contact.errors?.email">Invalid Format. Please use the following format
					user@domain.tld</ng-container>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="{{
                this.configurationService.config.inputAppearance || fill
            }}" [class.ctw-mb-0]="this.configurationService.config.brandConfig.id === 'imh'">
			<mat-label>Billing Email</mat-label>
			<input [(ngModel)]="billingEmail" [disabled]="!this.accountService.hasAccess('write')" type="email"
				#billing="ngModel" name="billingEmail" email matInput placeholder="billing@company.com" />
			<mat-error>
				<ng-container *ngIf="billing.errors?.email">Invalid Format. Please use the following format
					user@domain.tld</ng-container>
			</mat-error>
		</mat-form-field>

		<div [hidden]="!this.accountService.hasAccess('write') || this.configurationService.config.brandConfig.id !== 'imh'">
			<central-phone-number [isRequired]="true" [value]="this.contactPhone" #phoneNumber></central-phone-number>
		</div>

		<div class="update-message ctw-mt-4 ctw-mb-8 ctw-text-center">
			<div class="ctw-text-green-700" [hidden]="this.state !== 'success'">
				Successfully updated your {{
				this.configurationService.config.groupLabels[
				'group-label-title-case'
				]
				}}'s details.
			</div>
			<div class="ctw-text-red-700" [hidden]="this.state !== 'failed'">
				An error occurred while processing your request.
			</div>
		</div>
		<div class="action-buttons ctw-text-right" *ngIf="this.accountService.hasAccess('write')">
			<progress-button [state]="state" color="{{
                    this.configurationService.config.buttonColor || 'primary'
                }}">Update Your
				{{
				this.configurationService.config.groupLabels[
				'group-label-title-case'
				]
				}}'s Contact Info
			</progress-button>
		</div>
	</form>
	<central-payment-method *ngIf="this.configurationService.config.brandConfig.id === 'imh'"
		(status)="this.state = $event"></central-payment-method>
</div>
