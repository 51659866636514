<div class="form" [class.captcha-required]="captchaRequired">
	<div [hidden]="state === 'failed' || this.state === 'recaptcha'">
		<form class="login-form" (ngSubmit)="submit()" novalidate [hidden]="
				providerState === 'complete' || !this.hasAuthMethod('email')
			" class="{{this.configService.config.brandConfig.id}}">
			<div [hidden]="'pending' !== emailState">
				<mat-form-field [appearance]="inputAppearence" *ngIf="this.enableNameInput">
					<mat-label>Your Name</mat-label>
					<input name="name" required [(ngModel)]="this.name" #nameField="ngModel" matInput
						placeholder="Your Name" class="signup-name" />
					<mat-error>
						<ng-container *ngIf="nameField.errors?.required">This field is required</ng-container>
					</mat-error>
				</mat-form-field>
				<mat-form-field [appearance]="inputAppearence">
					<mat-label>{{ emailInputLabel }}</mat-label>
					<input matInput class="validated signin-email" [disabled]="this.emailState === 'passwordEntry'"
						name="email" email [(ngModel)]="model.email" placeholder="jdoe@company.com" autofocus
						type="text" (input)="useSocial = ''" (change)="sanitizeEmail()" required
						#emailField="ngModel" />

					<mat-hint align="start" class="animate__animated error" [class.animate__slideInUp]="
							fieldHasError(emailField) || useSocial || this.invalidEmailDomain
						">
						<span *ngIf="!useSocial">Valid email is required (ex:
							user@domain.tld).</span>
						<span *ngIf="useSocial">Please use the social account highlighted
							below.</span>
					</mat-hint>
				</mat-form-field>
			</div>
			<div style="margin-bottom: 1rem" [hidden]="'accept-terms-email' !== emailState">
				<ng-container>
					<ng-container *ngTemplateOutlet="termsCheckbox"></ng-container>
				</ng-container>
			</div>
			<div [hidden]="
					'pending' !== emailState &&
					'accept-terms-email' !== emailState
				">
				<button type="submit" [disabled]="
						this.state === 'submitted' ||
						('accept-terms-email' === emailState &&
							!this.termsApprove)
					" class="login-button" mat-button mat-raised-button color="primary">
					<mat-icon>email</mat-icon> Continue with email
				</button>
			</div>

			<div [hidden]="emailState !== 'passwordEntry'">
				<div *ngIf="temporaryAuthorization">
					<p>Please enter your temporary authorization code.</p>
				</div>
				<span class="entered-email"><mat-icon>person</mat-icon> {{ this.model.email }}</span>
				<mat-form-field [appearance]="inputAppearence" class="password-field">
					<mat-label>Enter your password</mat-label>
					<input matInput class="validated signin-auth" #password="ngModel" [(ngModel)]="model.password"
						name="password" autocomplete="off" type="password" required />

					<mat-hint class="animated error" [class.slideInUp]="
							fieldHasError(password) ||
							this.invalidPassword === model.password
						" align="start">
						<span *ngIf="
								fieldHasError(password) &&
								this.invalidPassword !== model.password
							">Password is required.</span>
						<span *ngIf="
								!fieldHasError(password) &&
								this.invalidPassword === model.password
							">The password you entered is invalid.</span>
					</mat-hint>
				</mat-form-field>

				<div class="row flex actions">
					<div class="col s6 left-align">
						<mat-checkbox name="rememberMe" [(ngModel)]="model.rememberMe" class="remember-me">Stay signed
							in</mat-checkbox>
					</div>
					<div class="col s6 right-align">
						<span class="lost-password"><a (click)="onForgotPassword.emit()"
								[routerLink]="['/forgot-password']">Forgot your password?</a></span>
					</div>
				</div>
				<button type="submit" name="sign-in" [disabled]="this.state === 'submitted'" class="login-button"
					mat-raised-button color="accent">
					<mat-icon>email</mat-icon> Sign In
				</button>
				<button type="button" [disabled]="this.state === 'submitted'" (click)="this.emailState = 'pending'"
					class="login-button back" mat-raised-button>
					<mat-icon>arrow_back</mat-icon> Not You?
				</button>
			</div>
			<div [hidden]="emailState !== 'accept-terms'">
				<ng-container *ngTemplateOutlet="termsCheckbox"></ng-container>
				<button *ngIf="!this.isModal" mat-raised-button type="button" color="primary"
					[disabled]="!this.termsApprove" (click)="acceptTerms()">
					Create Account
				</button>
				<ng-container *ngIf="this.isModal">
					<button name="sign-up" [disabled]="this.state === 'submitted'" class="login-button"
						mat-raised-button color="primary" (click)="setTerms(true, true)">
						<mat-icon>thumb_up</mat-icon> Yes, Create My Account!
					</button>
					<button type="button" [disabled]="this.state === 'submitted'" (click)="this.cancel()"
						class="login-button back" mat-raised-button>
						<mat-icon>arrow_back</mat-icon> Go Back
					</button>
				</ng-container>
			</div>
			<div [hidden]="
					this.isModal ||
					emailState !== 'signup' ||
					!this.allowSignup ||
					this.autoSignup
				" class="signup">
				<p class="signup__title">Would you like to sign up?</p>
				<p class="signup__body">
					We didn't find an account for
					<em>{{ this.model.email }}</em>
				</p>
				<button type="submit" name="sign-up" [disabled]="this.state === 'submitted'" class="login-button"
					mat-raised-button color="primary">
					<mat-icon>thumb_up</mat-icon> Yes, Sign Me Up!
				</button>
				<button type="button" [disabled]="this.state === 'submitted'" (click)="this.cancel()"
					class="login-button back" mat-raised-button>
					<mat-icon>arrow_back</mat-icon> Go Back
				</button>
				<p class="privacy">
					We will not sell or distribute your email address to any
					third party at any time. For more information view our
					<a target="_blank" href="{{
							this.configService.config.urls.privacyPolicy
						}}">Privacy Policy</a>.
				</p>
			</div>
			<div [hidden]="
					emailState !== 'signup' ||
					!this.allowSignup ||
					this.autoSignup
				" class="signup">
				<ng-container *ngTemplateOutlet="termsCheckbox"></ng-container>
				<button type="submit" name="sign-up" [disabled]="this.state === 'submitted'" class="login-button"
					mat-raised-button color="primary">
					<mat-icon>thumb_up</mat-icon> Yes, Sign Me Up!
				</button>
				<button type="button" [disabled]="this.state === 'submitted'" (click)="this.cancel()"
					class="login-button back" mat-raised-button>
					<mat-icon>arrow_back</mat-icon> Go Back
				</button>
			</div>
			<div *ngIf="emailState === 'signup' && !this.allowSignup">
				<p class="signup__body">
					We didn't find an account for
					<em>{{ this.model.email }}</em>
				</p>
				<button type="button" class="login-button back" [routerLink]="['/sign-up']"
					[queryParams]="{ email: this.model.email }" color="primary" queryParamsHandling="merge"
					mat-raised-button>
					<mat-icon>account_circle</mat-icon> Create an Account
				</button>
				<button type="button" (click)="this.emailState = 'pending'" class="login-button back" mat-raised-button>
					<mat-icon>arrow_back</mat-icon> Go Back
				</button>
			</div>
			<div *ngIf="emailState === 'alt-email'">
				<h2 class="ctw-font-bold">Email Required</h2>
				<p class="signup__body">
					We could not locate an email address on your <em> {{
						this.providerDataTmp.provider.providerId.replace('.com', '') }} </em> profile. Please a provide
					valid email address to complete account setup.
				</p>
				<p class="ctw-text-imh-gray-200 ctw-text-sm ctw-font-bold">You will still be able to sign-in with <em>{{
						this.providerDataTmp.provider.providerId.replace('.com', '') }}</em></p>
				<mat-form-field [appearance]="inputAppearence">
					<mat-label>Email</mat-label>
					<input matInput class="validated social-email" name="email" email [(ngModel)]="model.email"
						placeholder="jdoe@company.com" autofocus type="text" (input)="useSocial = ''"
						(change)="sanitizeEmail()" required #emailField="ngModel" />
					<mat-hint align="start" class="animate__animated error" [class.animate__slideInUp]="
							fieldHasError(emailField) || useSocial || this.invalidEmailDomain
						">
						<span *ngIf="!useSocial">Valid email is required (ex:
							user@domain.tld).</span>
						<span *ngIf="useSocial">Please use the social account highlighted
							below.</span>
					</mat-hint>
				</mat-form-field>
				<button type="submit" [disabled]="this.state === 'submitted'" class="login-button" mat-button
					mat-raised-button color="primary">Continue
				</button>
				<button type="button" [disabled]="this.state === 'submitted'" (click)="this.cancel()"
					class="login-button back" mat-raised-button>
					<mat-icon>arrow_back</mat-icon> Go Back
				</button>
			</div>
		</form>
		<div [hidden]="emailState !== 'twoFa'" class="ctw-text-left">
			<central-two-factor-auth *ngIf="emailState === 'twoFa'" [twoFaUserData]="this.twoFaUserData"
				action="sign-in" [autoSend]="true" [inputAppearance]="this.inputAppearence" [enableBackup]="true"
				[showDeviceSelection]="this.totalDevices() > 1" [showBtnSpinner]="false" [state]="this.getTwoFaState()"
				(twoFaCancel)="this.reset()" (twoFaSubmit)="this.setHeaders($event)"></central-two-factor-auth>
		</div>
		<resend-confirmation [token]="this.confirmationToken" (onComplete)="this.cancel()"
			*ngIf="this.emailState === 'account-setup'" [isModal]="this.isModal" [email]="this.model.email"></resend-confirmation>

		<div [hidden]="'pending' !== emailState">
			<p class="or" [hidden]="
					providerState === 'complete' || !this.hasAuthMethod('email')
				">
				or sign in using
			</p>
			<firebase-auth [hint]="useSocial" [authMethods]="this.authMethods"></firebase-auth>
		</div>
	</div>

	<div *ngIf="state === 'failed'" class="error-message">
		<p class="title">Oops, that's not right.</p>
		<p *ngIf="!this.errorMessage">
			We're sorry, an unexpected issue occurred while processing your
			request. Please try again and see if it corrects your problem.
		</p>
		<p *ngIf="this.errorMessage">{{ this.errorMessage }}</p>
		<div class="action-buttons">
			<button mat-raised-button mat-button color="accent" (click)="this.reset()">
				Okay
			</button>
		</div>
	</div>

	<div [hidden]="state !== 'recaptcha'" class="error-message recaptcha">
		<p>Please complete the following prompt to continue</p>

		<div class="captcha" #captcha></div>

		<div class="action-buttons">
			<button mat-raised-button mat-button (click)="this.state = 'pending'">
				Back
			</button>
		</div>
	</div>

	<div *ngIf="state === 'submitted'" class="card-loading valign-wrapper">
		<mat-progress-spinner mode="indeterminate" color="{{
				this.configService.config.brandConfig.id === 'imh'
					? 'primary'
					: 'accent'
			}}"></mat-progress-spinner>
	</div>
</div>

<ng-template #termsCheckbox>
	<div class="terms-box ctw-text-left terms-box-alt ctw-mb-5" *ngIf="this.configService.config.terms?.display === 'version_2'">
		<div>
			By clicking "I AGREE", you agree to our <a target="_blank" [href]="this.configService.config.brandConfig.termsOfService"
				>Universal Terms of Service</a>, <a target="_blank" [href]="this.configService.config.brandConfig.privacyPolicy"
				>Privacy Policy</a>, and <a target="_blank" [href]="this.configService.config.urls.cloudAgreement"
				>Cloud Services Agreement</a>.
		</div>
		<mat-checkbox class="ctw-mt-5" (change)="setTerms($event.checked)">
			I AGREE
		</mat-checkbox>
	</div>
	<ng-container *ngIf="this.configService.config.terms?.display === 'version_1' || !this.configService.config.terms?.display">
		<div [hidden]="this.isModal" class="terms-box">
			<mat-checkbox (change)="setTerms($event.checked)">
				<p class="terms-wrap">
					I have read, understand, and accept the
					{{ this.configService.config.brandConfig.reseller || '' }}
					<a href="{{
							this.configService.config.brandConfig.termsOfService
						}}" target="_blank">Terms of Service</a>
					and {{ this.configService.config.brandConfig.reseller || '' }}
					<a href="{{
							this.configService.config.brandConfig.privacyPolicy
						}}" target="_blank">Privacy Policy</a>.
				</p>
			</mat-checkbox>
		</div>
		<div *ngIf="this.isModal">
			<p class="terms-wrap ctw-mb-8 ctw-text-lg">
				I have read, understand, and accept the
				{{ this.configService.config.brandConfig.reseller || '' }}
				<a href="{{
						this.configService.config.brandConfig.termsOfService
					}}" target="_blank">Terms of Service<mat-icon
						class="newtab ctw-h-5 ctw-ml-1 ctw-mr-1 ctw-relative ctw-text-base ctw-text-imh-blue ctw-w-3.5">open_in_new</mat-icon></a>
				and {{ this.configService.config.brandConfig.reseller || '' }}
				<a href="{{
						this.configService.config.brandConfig.privacyPolicy
					}}" target="_blank">Privacy Policy<mat-icon
						class="newtab ctw-h-5 ctw-ml-1 ctw-mr-1 ctw-relative ctw-text-base ctw-text-imh-blue ctw-w-3.5">open_in_new</mat-icon></a>.
			</p>
		</div>
	</ng-container>
</ng-template>
